import React, { useState, useEffect } from 'react';
import {Table, Modal, Button, Input, Radio, Select, Switch, Form, Space,Dropdown, Menu  } from 'antd';
import { postBrokerCardGroup, postCreateApplication } from "../../../config/crm/apiBroker";
import {addMessage, addToast} from "../../../components/Notification/Toast";
import { DownOutlined } from '@ant-design/icons';

const BrokerAdv = ({ team }) => {
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedGroupID, setSelectedGroupID] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchGroups = async () => {
            const body = { team };
            try {
                setLoading(true);
                const response = await postBrokerCardGroup(body);
                if (response.code === 1000) {
                    setGroups(response.data);
                }
            } catch (error) {
                await addToast('warning', 'Ошибка загрузки групп', `${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchGroups();
    }, [team]);

    const handleCreateApplication = async () => {
        try {
            const values = await form.validateFields();
            const body = {
                team,
                body: {  groupID: selectedGroupID,
                    amount: values.amount,
                    typeID: values.transferType,
                    rangeIDs: values.selectOptions || [],
                    allowInterbankTx: values.interbankSwitch,
                    forDashboard: false
                }

            };

            console.log('Отправка заявки:', body);

            const response = await postCreateApplication(body);
            if (response.code === 1000) {
                // Дополнительные действия при успешной отправке
                setModalVisible(false); // Закрыть модальное окно после успешной отправки
                form.resetFields(); // Сбросить поля формы
                await addToast('success', 'Успех', 'Заявка успешно создана');

            } else {
                await addToast('error', 'Ошибка', `${response.msg}.`);

            }
        } catch (error) {
            await addToast('warning', 'Ошибка создания заявки', `${error.message}`);

        }
    };

    const quickApplications = [
        {
            label: 'Карта 10к ( 200 тысяч )',
            amount: 200000,
            typeID: 2,
            rangeIDs: [3],
        }
    ];
    const handleQuickApplication = async (groupID, quickApp) => {
        try {
            const body = {
                team,
                body: {
                    groupID: groupID,
                    amount: quickApp.amount,
                    typeID: quickApp.typeID,
                    rangeIDs: quickApp.rangeIDs,
                    allowInterbankTx: false, // Опционально
                    forDashboard: false,
                },
            };

            const response = await postCreateApplication(body);
            if (response.code === 1000) {
                await addToast('success', 'Успех', 'Быстрая заявка успешно создана');
            } else {
                await addToast('error', 'Ошибка', `${response.msg}.`);
            }
        } catch (error) {
            await addToast('warning', 'Ошибка создания быстрой заявки', `${error.message}`);
        }
    };

    const renderQuickActionsMenu = (groupID) => (
        <Menu>
            {quickApplications.map((quickApp, index) => (
                <Menu.Item key={index} onClick={() => handleQuickApplication(groupID, quickApp)}>
                    {quickApp.label}
                </Menu.Item>
            ))}
        </Menu>
    );
    const columns = [
        {
            title: 'Группа',
            dataIndex: 'group_name',
            key: 'group_name',
            width: '20%',
        },
        {
            title: 'Карта',
            dataIndex: 'payout_requisites',
            key: 'payout_requisites',
            width: '20%',
        },
        {
            title: 'Действие',
            key: 'action',
            render: (text, record) => (
                <Button type="primary" onClick={() => handleOpenModal(record)}>Создать заявку</Button>
            ),
            width: '20%',

        },
        {
            title: 'Быстрые заявки',
            key: 'quickActions',
            render: (text, record) => (
                <Dropdown overlay={renderQuickActionsMenu(record.id)} trigger={['hover']}>
                    <Button>
                        Быстрые заявки <DownOutlined />
                    </Button>
                </Dropdown>
            ),
            width: '40%',
        },
    ];

    const handleOpenModal = (record) => {
        setSelectedGroupID(record.id); // Устанавливаем выбранную группу для заявки
        setModalVisible(true);
    };

    const handleCancelModal = () => {
        setModalVisible(false);
        form.resetFields(); // Сбросить поля формы
    };

    return (
        <>
            <Table columns={columns} dataSource={groups} loading={loading} rowKey="id" />

            <Modal
                title="Создать заявку"
                open={modalVisible}
                onOk={handleCreateApplication}
                onCancel={handleCancelModal}
                okText="Отправить"
                cancelText="Отмена"
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        transferType: null, // Значение по умолчанию для transferType
                        selectOptions: [], // Пустой массив для selectOptions
                        interbankSwitch: false // Значение по умолчанию для interbankSwitch
                    }}
                >
                    <Form.Item
                        name="amount"
                        label="Объем"
                        rules={[
                            { required: true, message: 'Пожалуйста, введите объем' },
                            { type: 'number', min: 10000, message: 'Объем должен быть не менее 10000' },
                        ]}
                        getValueFromEvent={event => Number(event.target.value)}
                    >
                        <Input
                            placeholder="Объем"
                            type="number"
                        />
                    </Form.Item>
                    <Form.Item
                        name="transferType"
                        label="Тип перевода"
                    >
                        <Radio.Group>
                            <Radio value={1}>Только карты</Radio>
                            <Radio value={2}>Только СБП</Radio>
                            <Radio value={8}>По Счету</Radio>
                            {/*<Radio value={3}>Мобильный</Radio>*/}
                            <Radio value={null}>Все</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="selectOptions"
                        label="Диапазоны"
                    >
                        <Select mode="multiple">
                            <Select.Option value={1}>от 2000 до 5000</Select.Option>
                            <Select.Option value={2}>от 5000 до 10000</Select.Option>
                            <Select.Option value={3}>от 10000 до 50000</Select.Option>
                            <Select.Option value={4}>от 50000 до 300000</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="interbankSwitch"
                        label="Межбанковский перевод"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default BrokerAdv;
