import {DES_URL} from "../configUrls";
import {postApiRequest} from "../apiParams";

export const postDesInfoUser= (team) => {
    return postApiRequest(`${DES_URL}/info-user`, {team});
};

export const postDesStatUser= (team) => {
    return postApiRequest(`${DES_URL}/info-stat`, {team});
};

export const postDesInfoBalance= (team) => {
    return postApiRequest(`${DES_URL}/info-balance`, {team});
};

export const postDesInfoCourse= (team) => {
    return postApiRequest(`${DES_URL}/info-course`, {team});
};


export const postDesOrders = (body) => {
    return postApiRequest(`${DES_URL}/orders`, body);
};

export const postDesImageView= (body) => {
    return postApiRequest(`${DES_URL}/img-view`, body);
};


export const postDesListAdv= (team) => {
    return postApiRequest(`${DES_URL}/list-adv`, {team});
};

export const postDesNewAdv= (body) => {
    return postApiRequest(`${DES_URL}/new-adv`, body);
};

export const postDesEditAdv= (body) => {
    return postApiRequest(`${DES_URL}/edit-adv`, body);
};

export const postDesDelAdv= (body) => {
    return postApiRequest(`${DES_URL}/del-adv`, body);
};

export const postDesStatusAdv= (body) => {
    return postApiRequest(`${DES_URL}/status-adv`, body);
};


export const postDesDone = (body) => {
    return postApiRequest(`${DES_URL}/done`, body);
};

export const postDesCancel = (body) => {
    return postApiRequest(`${DES_URL}/cancel`, body);
};

export const postDesTransfer = (body) => {
    return postApiRequest(`${DES_URL}/transfer`, body);
};

export const uploadDesSession = (formData) => {
    return postApiRequest(`${DES_URL}/add-session`,formData, {
        'Content-Type': 'multipart/form-data'
    });
};