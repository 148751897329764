import React, { useEffect, useState, useRef } from 'react';
import {message} from "antd";
import Countdown from "react-countdown";

export const rendererTime = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span>00:00</span>;
    } else {
        const hoursDisplay = hours > 0 ? `${hours < 10 ? `0${hours}` : hours}:` : '';
        const minutesDisplay = `${minutes < 10 ? `0${minutes}` : minutes}`;
        const secondsDisplay = `${seconds < 10 ? `0${seconds}` : seconds}`;
        return <span>{hoursDisplay}{minutesDisplay}:{secondsDisplay}</span>;
    }
};

export const TimeRemainingRenderer = ({ tsCreate, beTimeMinutes }) => {
    const [timeRemaining, setTimeRemaining] = useState('');
    const intervalRef = useRef(null);

    useEffect(() => {
        const beTimeMilliseconds = beTimeMinutes * 60 * 1000;

        const updateRemainingTime = () => {
            const now = Date.now();
            const diff = tsCreate + beTimeMilliseconds - now; // Difference in milliseconds

            const isOverdue = diff < 0;
            const absoluteDiff = Math.abs(diff);
            const hours = Math.floor(absoluteDiff / 3600000);
            const minutes = Math.floor((absoluteDiff % 3600000) / 60000);
            const seconds = Math.floor((absoluteDiff % 60000) / 1000);

            const formatTime = (value) => value.toString().padStart(2, '0');

            let remainingTimeString = '';
            if (hours > 0) {
                remainingTimeString = `${isOverdue ? '-' : ''}${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
            } else {
                remainingTimeString = `${isOverdue ? '-' : ''}${formatTime(minutes)}:${formatTime(seconds)}`;
            }

            setTimeRemaining(remainingTimeString);
        };

        // Update the remaining time initially and then every second
        updateRemainingTime();
        intervalRef.current = setInterval(updateRemainingTime, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalRef.current);
    }, [tsCreate, beTimeMinutes]);

    return <span>{timeRemaining}</span>;
};
export const TimeElapsedRenderer = ({ tsCreate }) => {
    const [timeElapsed, setTimeElapsed] = useState('');
    const intervalRef = useRef(null);

    useEffect(() => {
        const updateElapsedTime = () => {
            const now = Date.now();
            const diff = Math.floor((now - tsCreate) / 1000); // Difference in seconds

            const hours = Math.floor(diff / 3600);
            const minutes = Math.floor((diff % 3600) / 60);
            const seconds = diff % 60;

            const formatTime = (value) => value.toString().padStart(2, '0');

            let elapsedTimeString = '';
            if (hours > 0) {
                elapsedTimeString = `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
            } else {
                elapsedTimeString = `${formatTime(minutes)}:${formatTime(seconds)}`;
            }

            setTimeElapsed((prev) => {
                if (prev !== elapsedTimeString) {
                    return elapsedTimeString;
                }
                return prev;
            });
        };

        updateElapsedTime(); // Initial update
        intervalRef.current = setInterval(updateElapsedTime, 1000); // Update every second

        return () => clearInterval(intervalRef.current); // Clean up interval
    }, [tsCreate]);

    return <span>{timeElapsed}</span>;
};
export const ReverseTimer = ({date}) => {
    return <Countdown
        date={date}
        renderer={rendererTime}
    />
};
export const ReverseTimer2 = ({ tsCreate }) => {
    const [elapsedTime, setElapsedTime] = useState(() => Date.now() - tsCreate);

    useEffect(() => {
        // Обновляем каждую секунду, чтобы актуализировать прошедшее время
        const interval = setInterval(() => {
            setElapsedTime(Date.now() - tsCreate);
        }, 1000);

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(interval);
    }, [tsCreate]);

    // Рассчитываем часы, минуты и секунды
    const isNegative = elapsedTime < 0;
    const absElapsedTime = Math.abs(elapsedTime);
    const hours = Math.floor(absElapsedTime / (1000 * 60 * 60));
    const minutes = Math.floor((absElapsedTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((absElapsedTime % (1000 * 60)) / 1000);

    // Форматируем вывод
    return (
        <span>
            {isNegative ? '-' : ''}
            {hours > 0 ? `${hours < 10 ? `0${hours}` : hours}:` : ''}
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
        </span>
    );
};

export async function handleCopy(value, content = 'Скопировано',showMessageWithValue = true) {
    try {
        console.log(value)
        await navigator.clipboard.writeText(value);
        const messageContent = showMessageWithValue ? `${content}: ${value}` : content;
        message.success(messageContent);
    } catch (err) {
        message.error('Ошибка при копировании');
    }
}


export async function handleCopyPay(value, content = 'Скопировано',onCou) {
    try {
        await navigator.clipboard.writeText(value);
        message.success(`${content}: ${value}`);
        onCou()
    } catch (err) {
        console.log(err)
        message.error('Ошибка при копировании текста');
    }
}

export async function handleCopyReplace(value, content = 'Скопировано') {
    const formattedValue = value.toString().replace(/\./g, ',');
    await handleCopy(formattedValue, content);
}

export async function handleDisabled(value) {
    try {
        message.info(`Разблокировка кнопки ${value}`);
    } catch (err) {
        message.error('Ошибка при разблокировки кнопки');
    }
}