import React, { useState } from 'react';
import {  Row, Col,Tabs } from 'antd';
import '../ControlPrice.css'
import AddBots from "./addBots"
import AddGoogle from "./addGoogle";
import BalanceComp from "./addBalance";
import AddCalc from "./addCalc";
import {TOTPTimer} from "./addGoogle";
import BrokerOrders from "../../../ProssPage/BrokerPage/BrokerOrders";
import BrokerGeneral from "../../../ProssPage/BrokerPage/BrokerGeneral";
import DesOrders from "../../../ProssPage/DesPage/DesOrders";
import DesGeneral from "../../../ProssPage/DesPage/DesGeneral";

function PageTabsPrice({marketActive, setMarketActive}) {
    const [reloadKey, setReloadKey] = useState(0); // Добавьте состояние для ключа перерендера
    const team = localStorage.getItem('team');
    const pageComponent1 = ["1", "2", "3", "4"].includes(team) ? (
        <BrokerOrders size={12} />
    ) : team === "6" ? (
        <DesOrders size={12} />
    ) : (
        <div>Нет доступа</div>
    );
    const pageComponent2 = ["1", "2", "3", "4"].includes(team) ? (
        <BrokerGeneral size={12} />
    ) : team === "6" ? (
        <DesGeneral size={12} />
    ) : (
        <div>Нет доступа</div>
    );
    const handleTabClick = (key) => {
        setMarketActive(key);
        setReloadKey(prev => prev + 1); // Увеличиваем ключ для перерендера

        return
    };
    const tabContentHeight = 'calc(100vh - 90px)'; // 50px - предполагаемая высота заголовка вкладок

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Tabs
                    tabPosition='top'
                    type={'line'}
                    centered={true}
                    tabBarExtraContent={{ right: <><TOTPTimer /></> }} // Добавление текста "Привет" справа

                    onTabClick={handleTabClick}
                    items={[
                        {
                            label: '2FA',
                            key: 'google',
                            children: <div key={reloadKey}><AddGoogle /></div>  // Используйте ключ для перерендера

                        },


                    {
                        label: 'Баланс',
                        key: 'balancePrice',
                        children:   <div key={reloadKey} style={{ maxHeight: tabContentHeight, overflowY: 'auto', overflowX: 'hidden' }}>

                        <BalanceComp size={12} />
                        </div>
                    },     {
                            label: 'Ботовод',
                            key: 'activBots',
                            children:
                                <AddBots />
                        } ,
                        {
                            label: 'Калькулятор',
                            key: 'calc',
                            children:
                            <>
                                <AddCalc/>
                            </>

                        },
                        {
                            label: 'Выплаты заявки ',
                            key: 'broker',
                            children:pageComponent1

                        },
                        {
                            label: 'Выплаты Управление',
                            key: 'brokerGeneral',
                            children:pageComponent2

                        }
                ]}
                    />
            </Col>

        </Row>
    );
}

export default PageTabsPrice;
