import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Upload, Image, Spin, Table, Tabs, message, Modal,Tooltip } from 'antd';
import { postDesOrders } from "../../../config/crm/apiDes";
import { UploadOutlined, ReloadOutlined, CopyOutlined } from '@ant-design/icons';
import OrderModal from "./ModalComponent";
import { addToast } from "../../../components/Notification/Toast";
import { formatNumber } from "../../../components/numericFunc";
import './customModal.css';
import {BankImageWrapper} from "../../../components/CommonComponents";
import {handleCopy, handleCopyPay, handleDisabled,TimeRemainingRenderer} from "../../../components/LogicComponents";
import {postDesDone} from "../../../config/crm/apiDes";

const { TabPane } = Tabs;
const refreshInterval = 60; // 60 секунд
const UploadModal = ({ visible, onClose, onSubmit, item }) => {
    const [fileList, setFileList] = useState([]);
    const [dragOver, setDragOver] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        // Добавляем обработчик вставки через Ctrl+V на всю страницу
        const handlePaste = (event) => {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
            for (let item of items) {
                if (item.kind === 'file' && item.type.startsWith('image/')) {
                    const file = item.getAsFile();
                    if (fileList.length === 0) {
                        const newFileList = [{
                            uid: '-1',
                            name: file.name,
                            status: 'done',
                            url: URL.createObjectURL(file),
                            originFileObj: file,
                        }];
                        setFileList(newFileList);
                    } else {
                        message.error('Можно загрузить только один файл.');
                    }
                    break;
                }
            }
        };

        document.body.addEventListener('paste', handlePaste);

        return () => {
            document.body.removeEventListener('paste', handlePaste);
        };
    }, [fileList]);

    const handleFileChange = (info) => {
        let fileList = [...info.fileList];

        // Limit to one file
        fileList = fileList.slice(-1);

        // Add url for preview
        fileList = fileList.map(file => ({
            ...file,
            url: URL.createObjectURL(file.originFileObj),
        }));

        setFileList(fileList);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const files = [...e.dataTransfer.files];
        if (files.length > 0 && fileList.length === 0) {
            const file = files[0];
            const newFileList = [{
                uid: '-1',
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
                originFileObj: file,
            }];
            setFileList(newFileList);
        }
    };


    const handleOpenDialog = () => {
        fileInputRef.current.click(); // открываем диалог выбора файла
    };

    const handleSend = () => {
        if (fileList.length === 0) {
            message.error('Пожалуйста, загрузите файл.');
            return;
        }
        onSubmit(fileList, item);
        setFileList([]);
        onClose();
    };

    const handlePreviewClick = (e) => {
        if (fileList.length === 0) {
            handleOpenDialog(); // открываем диалог выбора файла при клике на предпросмотр, если файл не загружен
        }
        e.preventDefault(); // отменяем стандартное действие клика, чтобы не открывался обзор файлов
    };

    const beforeUpload = (file) => {
        // Предотвращаем автоматическую загрузку файла
        return false;
    };

    return (
        <Modal
            title={item ? `Документ на сумму ${formatNumber(item.totalPrice)} карты ${item.payCard}` : 'Загрузить изображение'}
            open={visible}
            onOk={handleSend} // вызываем handleSend при нажатии кнопки "Отправить"
            onCancel={onClose}
            centered
            okText="Отправить"
            cancelText="Отменить"
        >
            <div
                className={`upload-area ${dragOver ? 'drag-over' : ''}`}
                onClick={handlePreviewClick}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {fileList.length === 0 ? (
                    <>
                        <UploadOutlined style={{ fontSize: 40, marginBottom: 8 }} />
                        <p>Перетащите файл сюда или вставьте с помощью Ctrl+V</p>
                    </>
                ) : (
                    <Image
                        width={200}
                        src={fileList[0].url}
                        alt={fileList[0].name}
                        style={{ display: 'block', margin: '0 auto' }}
                    />
                )}
            </div>
            <Upload
                accept=".jpg,.jpeg,.pdf"
                fileList={fileList}
                beforeUpload={beforeUpload} // предотвращаем автоматическую загрузку при выборе файла
                onChange={handleFileChange}
                style={{ display: 'none' }} // скрываем стандартный компонент загрузки файла
            >
                <Button ref={fileInputRef} style={{ display: 'none' }} />
            </Upload>
        </Modal>
    );
};


const DesOrders = () => {
    const [dataOrders, setDataOrders] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('PEND');
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [countdown, setCountdown] = useState(refreshInterval);
    const [scrollY, setScrollY] = useState(window.innerHeight);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [fileList, setFileList] = useState([]);

    const [activeButtonsPayCard, setActiveButtonsPayCard] = useState(
        dataOrders.map(() => ({ disabled: true, success: false }))
    );

    const [activeButtonsTotalPrice, setActiveButtonsTotalPrice] = useState(
        dataOrders.map(() => ({ disabled: true, success: false }))
    );
    const updateTableScroll = () => {
        const otherElementsHeight = 150; // Примерное значение, измените на ваше
        const availableHeight = window.innerHeight - otherElementsHeight;
        setScrollY(availableHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', updateTableScroll);

        // Вызов при монтировании
        updateTableScroll();

        // Очистка при размонтировании
        return () => {
            window.removeEventListener('resize', updateTableScroll);
        };
    }, []);
    const createStatusButton = (status, item, text, className) => (
        <button className={`btn btn-${className} btn-sm w-100`}>{text}</button>
    );
    const fetchOrders = useCallback(async (status, page) => {
        setLoading(true);
        const team = localStorage.getItem('team');
        const body = {
            team,
            orderStatus: status === 'all' ? null : status,
            page: page,
            size: pageSize,
        };

        try {
            const response = await postDesOrders(body);
            setDataOrders(response.data);
            setTotalRecords(response.total);
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
            message.error(`Не удалось обновить список ордеров`);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchOrders(activeTab, currentPage);
    }, [fetchOrders, activeTab, currentPage,pageSize]);

    useEffect(() => {
        let intervalId;
        let countdownInterval;

        if (autoRefresh) {
            intervalId = setInterval(() => {
                fetchOrders(activeTab, currentPage);
                setCountdown(refreshInterval); // Сбросить таймер
            }, refreshInterval * 1000); // Запустить автообновление каждые 60 секунд

            countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown > 0 ? prevCountdown - 1 : refreshInterval);
            }, 1000); // Уменьшить счетчик каждую секунду
        } else {
            setCountdown(refreshInterval); // Сбросить таймер при выключении автообновления
        }

        return () => {
            clearInterval(intervalId);
            clearInterval(countdownInterval);
        };
    }, [autoRefresh]);
    const handleTabChange = (key) => {
        const selectedTab = tabData.find(tab => tab.id === key);
        setActiveTab(selectedTab.status);
        setCurrentPage(1); // Сбрасываем на первую страницу при смене вкладки
    };

    const handlePageChange = (pagination) => {

        setCurrentPage(pagination.current);
        setPageSize(pagination.page);
    };

    const handleOpenModal = (item) => {
        setSelectedItem(item);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedItem(null);
    };

    const handleRightClick2 = useCallback((event) => {
        event.preventDefault();
        setAutoRefresh(!autoRefresh);
    }, [autoRefresh]);

    const updateButton = (
        <Button
            onContextMenu={handleRightClick2}
            onClick={() => {
                setLoading(true);
                fetchOrders(activeTab, currentPage);
            }}
            disabled={loading}
            icon={loading ? <Spin /> : <ReloadOutlined />}
        >
            Обновить {autoRefresh ? `(${countdown} сек)` : ""}
        </Button>
    );



    let tabBarExtraContent = {
        right: updateButton
    };
    const OpenOrderRender = (_, item) => (

        <div
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenModal(item)}
        >
            <BankImageWrapper bankName={item.bankName}/>
        </div>
    );

    const copyPayCardBotAndDeactivate = async (text, team, orderNumber, tradeType) => {
        await handleCopyPay(text, "Карта", () => {
            setActiveButtonsPayCard((prevState) => ({
                ...prevState,
                [orderNumber]: { disabled: true, success: true }
            }));
        });
    };

    const handleRightClick = async (event, orderNumber, type) => {
        event.preventDefault(); // Предотвращаем появление стандартного контекстного меню

        // Проверяем, заблокирована ли кнопка
        const isButtonDisabled = type === 'payCard'
            ? activeButtonsPayCard[orderNumber]?.disabled
            : activeButtonsTotalPrice[orderNumber]?.disabled;

        if (isButtonDisabled) {
            // Если кнопка заблокирована, то разблокируем ее
            if (type === 'payCard') {
                setActiveButtonsPayCard(prevState => ({
                    ...prevState,
                    [orderNumber]: { disabled: false, success: false }
                }));
                await handleDisabled('"Номер карта"')
            } else if (type === 'totalPrice') {
                setActiveButtonsTotalPrice(prevState => ({
                    ...prevState,
                    [orderNumber]: { disabled: false, success: false }
                }));
                await handleDisabled('"Итоговая цена"')
            }
        }
        // Если кнопка не заблокирована, ничего не делаем
    };


    const StatusColumnRender = (status, item) => {
        switch (status) {
            case 'COMPLETE':
                return (
                    <button className={`btn btn-success btn-sm w-100`}>Исполнен</button>
                );
            case 'CANCEL':
                return createStatusButton(status, item, 'Отменен', 'warning');
            case 'CANCEL_SYS':
                return createStatusButton(status, item, 'На отмену', 'danger');

            case 'APPEAL':
                return  (
                    <button
                        className={`btn btn-danger btn-sm w-100`}
                        onClick={(e) => handleContextMenu2(e, item)}
                    > Апелляция
                    </button>
                );
            case 'PEND':
                return (
                    <button
                        className={'btn btn-outline-secondary btn-sm w-100'}
                        onClick={(e) => handleContextMenu2(e, item)}

                    >
                        Оплатить {' '}
                        <TimeRemainingRenderer tsCreate={item.tsCreate} beTimeMinutes={60} />
                    </button>


                );

            case 'PAID':
                return (
                    <button
                        className={'btn btn-outline-secondary btn-sm w-100'}
                        onClick={(e) => handleContextMenu2(e, item)}

                    >
                        Оплачено {' '}
                        <TimeRemainingRenderer tsCreate={item.tsUpdate} beTimeMinutes={30} />
                    </button>
                );
            default:
                return null;
        }
    };

    const PayCardColumnRender = (_, item) => {
        const isDisabled = item.orderStatus === 'APPEAL' || item.orderStatus === 'CANCEL' || item.orderStatus === 'COMPLETE' || activeButtonsPayCard[item.orderNumber]?.disabled;
        const shouldButtonBeGreenAndDisabled = isDisabled && item.tradeType === "BUY";
        const successClass = shouldButtonBeGreenAndDisabled || activeButtonsPayCard[item.orderNumber]?.success ? 'btn-success' : 'btn-outline-secondary';

        return (
            <button
                className={`btn ${successClass} ${isDisabled ? 'disabled-style' : ''} btn-sm w-100`}
                onClick={() => {
                    if (!isDisabled) {
                        copyPayCardBotAndDeactivate(item.payCard, item.team, item.orderNumber, item.tradeType);
                    }
                }}
                onContextMenu={(e) => handleRightClick(e, item.orderNumber, 'payCard')}
            >
                {item.payCard || "Карта"}
            </button>
        );
    };

    const SpeedButton = (_, item) => {

        return (
            <button
                className={`btn btn-sm btn-outline-secondary w-100`}
                onClick={() => {
                    handleCopy(`Одним платежом пожалуйста\nРовно ${item.totalPrice} ₽\n${item.bankName}\n${item.payCard}`,)
                    setActiveButtonsPayCard((prevState) => ({
                        ...prevState,
                        [item.orderNumber]: { disabled: true, success: true }
                    }));
                }}
            >
                <CopyOutlined />
            </button>
        );
    };
    const copyTotalPriceAndDeactivate = async (text, orderNumber) => {
        await handleCopyPay(text, "Цена", () => {
            setActiveButtonsTotalPrice((prevState) => ({
                ...prevState,
                [orderNumber]: { disabled: true, success: true }
            }));
        });
    };
    const TotalPriceColumnRender = (_, item) => {
        const isDisabled = item.orderStatus === 'APPEAL' || item.orderStatus === 'CANCEL' || item.orderStatus === 'COMPLETE' || activeButtonsTotalPrice[item.orderNumber]?.disabled;
        const shouldButtonBeGreenAndDisabled = isDisabled && item.tradeType === "BUY";
        const successClass = shouldButtonBeGreenAndDisabled || activeButtonsTotalPrice[item.orderNumber]?.success ? 'btn-success' : 'btn-outline-secondary';

        return (
            <button
                className={`btn ${successClass} ${isDisabled ? 'disabled-style' : ''} btn-sm w-100`}
                onClick={() => {
                    if (!isDisabled) {
                        copyTotalPriceAndDeactivate(Number(item.totalPrice), item.orderNumber);
                    }
                }}
                onContextMenu={(e) => handleRightClick(e, item.orderNumber, 'totalPrice')}
            >
                {Number(item.totalPrice).toLocaleString('ru-RU')}
            </button>
        );
    };
    const getColumns = () => [
        {
            title: 'Карта',
            dataIndex: 'payCard',
            key: 'payCard',
            width: '20%',
            render: PayCardColumnRender,

        },
        {
            title: 'Сумма',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: '20%',
            sorter: (a, b) => a.totalPrice - b.totalPrice,
            render: TotalPriceColumnRender,

        },
        {
            title: 'Форма',
            dataIndex: 'payCard',
            key: 'speed',
            width: '9%',
            render: SpeedButton,
        },
        {
            title: 'Статус',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            width: '20%',
            render: StatusColumnRender,

        },
        // {
        //     title: 'Ордер',
        //     dataIndex: 'orderNumber',
        //     key: 'orderNumber',
        //     render: (text, item) => {
        //         const handleClick = async () => {
        //             await handleCopy(` ${item.orderNumber}\n`, "Ордер", () => {
        //             });
        //         };
        //
        //         return (
        //             <span onClick={handleClick} style={{ cursor: 'pointer' }}>
        //                 {item.orderNumber}
        //             </span>
        //         );
        //     }
        // },
        {
            title: 'Курс',
            width: '7%',
            dataIndex: 'price',
            key: 'price',
            render: (text, item) => {
                if (item.agentFio) {
                    return (
                        <Tooltip title={item.agentFio}>
                        <span style={{ color: 'green', fontWeight: 'bold', cursor: 'pointer' }}
                              onClick={() => {
                                  handleCopy(`${item.agentFio}`,)
                              }}

                        >
                            {text}
                        </span>
                        </Tooltip>
                    );
                }
                return text;
            },
        },
        {
            title: 'Банк',
            width: '7%',
            dataIndex: 'bankName',
            key: 'bankName',
            render: OpenOrderRender,
        },
    ];

    const tabData = [
        { id: 'Активные', status: 'PEND' },
        { id: 'Оплаченные', status: 'PAID' },
        { id: 'На отмену', status: 'CANCEL_SYS' },
        { id: 'Отмененные', status: 'CANCEL' },
        { id: 'Завершенные', status: 'COMPLETE' },
        { id: 'Все', status: null },
    ];

    const handleUploadModalClose = () => {
        setIsUploadModalVisible(false);
        setCurrentItem(null);
        setFileList([]); // Clear file list after submission

    };
    const handleContextMenu2 = (e, item) => {
        e.preventDefault();
        setCurrentItem(item);
        setIsUploadModalVisible(true);
    };
    const handleModalSubmit = async (fileList, item) => {
        const file = fileList[0];
        const reader = new FileReader();

        reader.onloadend = async () => {
            const base64data = reader.result.split(',')[1];
            const body = {
                payNumber: item.orderNumberPay,
                orderNumber: item.orderNumber,
                team: item.team,
                totalPrice: item.totalPrice,
                proofs: base64data,
                filename: file.name,  // Добавляем имя файла в тело запроса

            };
            try {
                const response = await postDesDone(body);
                if (response.code === 1000) {
                    const updatedOrder = response.data;
                    const orderNumberToDelete = updatedOrder.orderNumber;
                    if (item.orderStatus !== "PAID") {
                        setDataOrders((prevOrders) =>
                            prevOrders.filter((order) => order.orderNumber !== orderNumberToDelete)
                        );
                    }

                    message.success(`Файл на сумму ${item.totalPrice} успешно загружен`);
                } else {
                    message.error(`Unexpected response code: ${response.code}`);
                }

            } catch (error) {
                message.error('Файл не загружен');
            }
        };

        reader.readAsDataURL(file.originFileObj);
    };
    return (
        <>
            <Tabs
                defaultActiveKey="Все"
                activeKey={tabData.find(tab => tab.status === activeTab)?.id || 'Все'}
                onChange={handleTabChange}
                tabBarExtraContent={tabBarExtraContent}
            >
                {tabData.map(tab => (
                    <TabPane tab={tab.id} key={tab.id}>
                        <Table
                            columns={getColumns()}
                            dataSource={dataOrders.map((order, index) => ({
                                ...order,
                                key: `${order.orderNumber}_${index}`,
                            }))}
                            pagination={{
                                current: currentPage,
                                pageSize,
                                total: totalRecords,
                                pageSizeOptions: ['10', '20', '30'],
                                showSizeChanger: true,
                            }}
                            scroll={{ y: scrollY ? `${scrollY - 120}px` : undefined }}
                            onChange={handlePageChange}

                            loading={loading}
                            rowKey="orderNumber"
                        />
                    </TabPane>
                ))}
            </Tabs>
            <OrderModal
                visible={modalVisible}
                onClose={handleCloseModal}
                orderInfo={selectedItem}
            />
            <UploadModal
                visible={isUploadModalVisible}
                onClose={handleUploadModalClose}
                onSubmit={handleModalSubmit}
                item={currentItem}
                fileList={fileList}
                setFileList={setFileList}
            />
        </>
    );
};

export default DesOrders;
