import React, { useState, useEffect } from 'react';
import { Skeleton, Table, Modal, Button } from 'antd';
import { postBrokerApplications, postBrokerCloseAdv } from "../../../config/crm/apiBroker";
import { formatNumber } from "../../../components/numericFunc";
import {addToast} from "../../../components/Notification/Toast";

const Applications = ({ team }) => {
    const [loading, setLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [advToClose, setAdvToClose] = useState(null);

    useEffect(() => {
        const fetchApplications = async () => {
            const body = { team };
            try {
                setLoading(true);
                const response = await postBrokerApplications(body);
                if (response.code === 1000) {
                    const formattedData = response.data.map(item => ({
                        ...item,
                        createdAt: formatDate(item.createdAt),
                        amount: {
                            total: formatNumber(item.amount.total, false),
                            paid: formatNumber(item.amount.paid, false),
                            left: formatNumber(item.amount.left, false),
                        },
                    }));
                    setApplications(formattedData);
                }
            } catch (error) {
                await addToast('error', 'Ошибка', `Загрузки заявок`);

            } finally {
                setLoading(false);
            }
        };

        fetchApplications();
    }, [team]);
    const getTypeName = typeID => {
        switch (typeID) {
            case 1: return 'Карта';
            case 2: return 'СБП';
            case 3: return 'Мобильный';
            default: return 'Все';
        }
    };

    const columns = [
        {
            title: 'Заявка',

            children: [
                { title: 'Номер', dataIndex: 'id', key: 'id' },
                { title: 'Тип', dataIndex: 'typeID', key: 'typeID', render: getTypeName },
                { title: 'Межбанк', dataIndex: 'allowInterbankTx', key: 'allowInterbankTx', render: (active) => (
                        active
                            ? 'Да'
                            : 'Нет'
                    ), },
                { title: 'Дата создания', dataIndex: 'createdAt', key: 'createdAt' },
            ],
        },
        {
            title: 'Сумма в ₽',
            children: [
                { title: 'Заявка', dataIndex: 'amount', key: 'amountTotal', render: amount => amount.total },
                { title: 'Выплачено', dataIndex: 'amount', key: 'amountPaid', render: amount => amount.paid },
                // { title: 'Осталось', dataIndex: 'amount', key: 'amountLeft', render: amount => amount.left },
            ],
        },
        {
            title: 'Ордера',
            children: [
                { title: 'Всего', dataIndex: 'count', key: 'countTotal', render: count => count.total },
                { title: 'Открыто', dataIndex: 'count', key: 'countOpen', render: count => count.open },
                { title: 'Закрыто', dataIndex: 'count', key: 'countClosed', render: count => count.closed },
            ],
        },
        {
            title: 'Статус',
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => (
                active
                    ? <Button type="primary" onClick={() => handleOpenModal(record)}>Закрыть</Button>
                    : 'Закрыто'
            ),
        },
    ];

    const formatDate = createdAt => new Date(createdAt * 1000).toLocaleDateString();



    const handleOpenModal = record => {
        setAdvToClose(record.id);
        setModalVisible(true);
    };

    const handleCloseModal = async () => {
        try {
            const body = { team, advNumber: advToClose };
            const response = await postBrokerCloseAdv(body);
            if (response.code === 1000) {
                if (response.data === true) {
                    // Обновляем список заявок после успешного закрытия
                    const updatedApplications = applications.map(item => {
                        if (item.id === advToClose) {
                            return { ...item, active: false };
                        }
                        return item;
                    });
                    setApplications(updatedApplications);
                    setModalVisible(false);
                } else {
                    await addToast('warning', 'Ошибка', response.msg || `Заявка не закрылась`);
                }
            } else {
                await addToast('warning', 'Ошибка', `Заявка не закрылась`);
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
        }
    };

    const handleCancelModal = () => {
        setModalVisible(false);
    };

    return (
        <>
            <Table columns={columns} dataSource={applications} loading={loading} rowKey="id"   scroll={{
                x: 500,
            }}/>

            <Modal
                title="Закрыть заявку"
                open={modalVisible}
                onOk={handleCloseModal}
                onCancel={handleCancelModal}
            >
                <p>Вы уверены, что хотите закрыть эту заявку?</p>
            </Modal>
        </>
    );
};

export default Applications;
