import React, { } from 'react';
import {Row, Col} from 'antd'
import DesGeneral from "./DesGeneral";
import DesOrders from "./DesOrders";
const DesPage = ({size=12}) => {
    return (

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={size}>
                    <DesOrders/>
                               </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={size}>
                    <DesGeneral/>
                </Col>
            </Row>

    );
};

export default DesPage;


