import React, { useState } from 'react';
import {Card, List, message, Modal, Skeleton, Tooltip, Button, Spin, Form, Radio} from 'antd';
import { postDesImageView } from "../../../config/crm/apiDes";
import {handleCopy, TimeRemainingRenderer} from "../../../components/LogicComponents";
import { LockOutlined } from "@ant-design/icons";
import { formatNumber } from "../../../components/numericFunc";
import { formattedDate } from "../../../components/dateFunc";
import { ReverseTimer } from "../../../components/LogicComponents";
import {addMessage, addToast} from "../../../components/Notification/Toast";
import {LeftOutlined,RightOutlined} from "@ant-design/icons";
import {postDesCancel,postDesTransfer} from "../../../config/crm/apiDes";

function renderInfoItem(label, value, tooltip = null, copy = null, defaultValue = '') {
    const content = (
        <span className="list-item-value">
            {copy ? (
                <span style={{ cursor: 'pointer' }} onClick={() => handleCopy(copy)}>{value}</span>
            ) : (
                value || defaultValue
            )}
        </span>
    );

    return (
        <List.Item className="list-item">
            <span className="list-item-label">{label}:</span>
            {tooltip ? (
                <Tooltip placement="left" title={tooltip}>
                    {content}
                </Tooltip>
            ) : (
                content
            )}
        </List.Item>
    );
}

const SkeletonCard = () => {
    const skeletonWidths = [
        { label: 'Дата', left: '20%', right: '50%' },
        { label: 'Ордер', left: '20%', right: '50%' },
        { label: 'Актив', left: '20%', right: '50%' },
        { label: '.....', left: '50%', right: '50%' },
        { label: 'Сумма', left: '20%', right: '50%' },
    ];

    return (
        <Card
            size="small"
            bordered={false}
            style={{ height: '100%' }}
            title={<div style={{ cursor: 'pointer' }}>Информация</div>}
        >
            <Spin tip="Загрузка...">
                <List>
                    {skeletonWidths.map((widths, index) =>
                        <React.Fragment key={index}>
                            <List.Item className="list-item">
                                <div style={{ width: widths.left, float: 'left' }}>
                                    <span className="list-item-label">{widths.label}:</span>
                                </div>
                                <div style={{ width: widths.right, float: 'right' }}>
                                    <Skeleton.Input size={14} active title={{ width: '100%' }} block={true} />
                                </div>
                            </List.Item>
                        </React.Fragment>
                    )}
                    <List.Item>
                        <button className={`btn btn-warning btn-sm w-100 disabled`}>
                            <LockOutlined />
                        </button>
                    </List.Item>
                </List>
            </Spin>
        </Card>
    );
}
const createStatusButton = (status, item, text, className) => (
    <button className={`btn btn-${className} btn-sm w-100`}>{text}</button>
);

const OtmenaOrders = ({ orderInfo, isReasonModalVisible, setIsReasonModalVisible }) => {
    const [loading, setLoading] = useState(false);
    const [reason, setSelectedReason] = useState(3); // Значение по умолчанию
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false); // Состояние для второго модального окна

    const handleReasonSubmit = async () => {
        const team = orderInfo.team;
        const orderNumber = orderInfo.orderNumber;
        const body = { team, orderNumber, reason };
        setLoading(true);
        try {
            const response = await postDesCancel(body);
            if (response.code === 1000) {
                await addMessage('success','Ордер успешно отменен!')
            } else {
                await addMessage('error','Не удалось отменить ордер!')
            }
        } catch (error) {
            await addMessage('error','Не удалось отменить ордер!')

        } finally {
            setLoading(false);
            setIsReasonModalVisible(false);
        }
    };

     const handleTransferSubmit = async () => {
         const team = orderInfo.team;
         const orderNumber = orderInfo.orderNumber;
         const body = { team, orderNumber };
         setLoading(true);
         try {
             const response = await postDesTransfer(body);
             if (response.code === 1000) {
                 await addMessage('success','Ордер успешно отменен!')
             } else {
                 await addMessage('error','Не удалось отменить ордер!')
             }
         } catch (error) {
             await addMessage('error','Не удалось отменить ордер!')

         } finally {
             setLoading(false);
             setIsReasonModalVisible(false);
         }
        await addMessage('success','Ордер успешно передан другому!')
        setIsTransferModalVisible(false);
    };

    return (
        <> <br/> <br/>
            <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                <button
                    className="btn btn-outline-danger btn-sm"
                    style={{ flex: 1 }}
                    onClick={() => setIsReasonModalVisible(true)}
                >
                    Отменить
                </button>
                <button
                    className="btn btn-outline-danger btn-sm"
                    style={{ flex: 1 }}
                    onClick={() => setIsTransferModalVisible(true)}
                >
                    Передать другому
                </button>
            </div>

            {/* Модальное окно для выбора причины отмены */}
            <Modal
                title="Выберите причину отмены"
                open={isReasonModalVisible}
                onCancel={() => setIsReasonModalVisible(false)}
                onOk={handleReasonSubmit}
                okText="Ок"
                cancelText="Отмена"
                confirmLoading={loading} // Показываем загрузку при подтверждении
            >
                <Form>
                    <Form.Item>
                        <Radio.Group
                            value={reason}
                            onChange={(e) => setSelectedReason(e.target.value)}
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <Radio value={3} style={{ marginBottom: '8px' }}>Красный блок</Radio>
                            <Radio value={5} style={{ marginBottom: '8px' }}>Нет банка</Radio>
                            <Radio value={6} style={{ marginBottom: '8px' }}>Проблема у банка</Radio>
                            <Radio value={7}>Проблема на моей стороне</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Модальное окно для подтверждения передачи другому */}
            <Modal
                title="Вы уверены, что хотите передать ордер другому?"
                open={isTransferModalVisible}
                onCancel={() => setIsTransferModalVisible(false)}
                onOk={handleTransferSubmit}
                okText="Да"
                cancelText="Нет"
            >
                <p>Этот ордер будет передан другому человеку. Подтвердите свое действие.</p>
            </Modal>
        </>
    );
};
const OrderModal = ({ visible, onClose, orderInfo }) => {
    const [mediaUrls, setMediaUrls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0); // Состояние для отслеживания текущего индекса медиа
    const [isMediaVisible, setIsMediaVisible] = useState(false); // Состояние для отображения медиафайлов

    const [isReasonModalVisible, setIsReasonModalVisible] = useState(false);

    const handleViewMedia = async () => {
        if (!orderInfo?.imageUrls || orderInfo.imageUrls.length === 0) {
            message.error('Нет доступных изображений');
            await addMessage('warning', 'Нет доступных изображений');
            return;
        }

        setLoading(true);
        try {
            const loadedMedia = [];
            for (let url of orderInfo.imageUrls) {
                const body = { team: orderInfo.team, imgUrl: url };
                const response = await postDesImageView(body);
                if (response.code === 1000) {
                    const base64Data = response.data; // Предполагается, что response.data содержит base64 строку
                    if (url.endsWith('.pdf')) {
                        loadedMedia.push({ type: 'pdf', data: base64Data });
                    } else {
                        loadedMedia.push({ type: 'image', data: `data:image/png;base64,${base64Data}` });
                    }
                }
            }
            if (loadedMedia.length > 0) {
                setMediaUrls(loadedMedia);
                setIsMediaVisible(true);
            } else {
                await addMessage('error', 'Не удалось загрузить файлы');
            }
        } catch (error) {
            await addMessage('error', 'Не удалось загрузить файлы');
        } finally {
            setLoading(false);
        }
    };

    const StatusColumnRender = (status, item) => {
        switch (status) {
            case 'COMPLETE':
                return (
                    <button className={`btn btn-success btn-sm w-100`}>Исполнен</button>
                );
            case 'CANCEL':
                return createStatusButton(status, item, 'Отменен', 'warning');
            case 'CANCEL_SYS':
                return createStatusButton(status, item, 'На отмену', 'danger');
            case 'APPEAL':
                return (
                    <>
                        <button
                            className={`btn btn-danger btn-sm w-100`}
                        >
                            Апелляция {' '}
                            <TimeRemainingRenderer tsCreate={item.tsUpdate} beTimeMinutes={30} />
                        </button>
                        <OtmenaOrders orderInfo={item} isReasonModalVisible={isReasonModalVisible} setIsReasonModalVisible={setIsReasonModalVisible}/>
                    </>

                );
            case 'PEND':
                return (
                    <>
                        <button
                            className={'btn btn-outline-secondary btn-sm w-100'}
                        >
                            Оплатить {' '}
                            <TimeRemainingRenderer tsCreate={item.tsCreate} beTimeMinutes={60} />
                        </button>

                        <OtmenaOrders orderInfo={item} isReasonModalVisible={isReasonModalVisible} setIsReasonModalVisible={setIsReasonModalVisible}/>
                    </>


                );
            case 'PAID':
                return (
                    <button
                        className={'btn btn-outline-secondary btn-sm w-100'}
                    >
                        Оплачено {' '}
                        <TimeRemainingRenderer tsCreate={item.tsUpdate} beTimeMinutes={30} />
                    </button>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Modal
                open={visible}
                onCancel={onClose}
                footer={null}
                centered
            >
                {orderInfo ? (
                    <Card
                        size="small"
                        bordered={false}
                        style={{ height: '100%' }}
                        title={
                            <div style={{ cursor: 'pointer' }}>
                                Информация {orderInfo.bankName} {formatNumber(orderInfo.totalPrice, true)}
                            </div>
                        }
                    >
                        <List>
                            {renderInfoItem('Ордер', orderInfo.orderNumberMini, false, orderInfo.orderNumberMini)}


                            {renderInfoItem('Дата заявки', formattedDate(orderInfo.tsCreate))}
                            {renderInfoItem('Последнее действие', formattedDate(orderInfo.tsUpdate))}
                            {orderInfo.tsPaid && renderInfoItem('Дата принятия', formattedDate(orderInfo.tsPaid))}
                            {orderInfo.tsPend && renderInfoItem('Дата подтверждения', formattedDate(orderInfo.tsPend))}
                            {orderInfo.tsComplete && renderInfoItem('Дата завершения', formattedDate(orderInfo.tsComplete))}
                            {renderInfoItem('Банк', orderInfo.bankName)}
                            {renderInfoItem('Карта', orderInfo.payCard, null, orderInfo.payCard)}
                            {renderInfoItem('Сумма', formatNumber(orderInfo.totalPrice, true), null, orderInfo.totalPrice)}
                            {StatusColumnRender(orderInfo.orderStatus, orderInfo)}
                            <br/><br/>
                            {orderInfo.imageUrls && orderInfo.imageUrls.length > 0 && (
                                <button className={`btn btn-outline-success btn-sm w-100 ${loading ? 'disabled' : ''}`} onClick={handleViewMedia}>
                                    Просмотр файла
                                </button>
                            )}
                        </List>
                    </Card>
                ) : (
                    <SkeletonCard />
                )}
            </Modal>

            {/* Модальное окно для просмотра медиафайлов */}
            <Modal
                open={isMediaVisible}
                onCancel={() => {
                    setCurrentIndex(0); // Сброс индекса при закрытии
                    setIsMediaVisible(false);
                }}
                foot
                footer={null}
                title={`Файл ${currentIndex + 1} из ${mediaUrls.length}`} // Заголовок с номером текущего файла
                centered
                width={600} // Фиксированная ширина модального окна
                style={{  bottom: '10vh' }} // Отступы сверху и снизу
                bodyStyle={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh', // Высота содержимого модального окна
                    position: 'relative',
                }}
            >
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* Левая кнопка "Назад" */}
                    {currentIndex > 0 && (
                        <Button
                            type="primary"
                            onClick={() => setCurrentIndex(currentIndex - 1)}
                            style={{
                                position: 'absolute',
                                left: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                            }}
                        >
                            <LeftOutlined />
                        </Button>
                    )}

                    {/* Отображение текущего медиафайла */}
                    {mediaUrls.length > 0 && mediaUrls[currentIndex] && (
                        mediaUrls[currentIndex].type === 'pdf' ? (
                            <iframe
                                src={`data:application/pdf;base64,${mediaUrls[currentIndex].data}`}
                                title={`Order PDF ${currentIndex + 1}`}
                                style={{ width: '100%', height: '100%', border: 'none' }}
                            />
                        ) : (
                            <img
                                src={mediaUrls[currentIndex].data}
                                alt={`Order Image ${currentIndex + 1}`}
                                style={{ width: 'auto', height: '100%', maxWidth: '90%', maxHeight: '100%', objectFit: 'contain' }}
                            />
                        )
                    )}
                    {/* Правая кнопка "Вперед" */}
                    {currentIndex < mediaUrls.length - 1 && (
                        <Button
                            type="primary"
                            onClick={() => setCurrentIndex(currentIndex + 1)}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                            }}
                        >
                            <RightOutlined />
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default OrderModal;
