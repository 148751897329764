import React, { useState } from 'react';
import { Table, Tag , Badge } from 'antd';
import {BankImageWrapper} from "../../../../../components/CommonComponents";
import OrderModal from '../../ModalComponent'
const  OrderTable =({ tableData, currentPage, handlePageClick, itemsPerPage, totalOrders,setItemsPerPage, loadingTable })  => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const getStatusBadge = (status) => {
        switch (status) {
            case 'COMPLETE':
                return <Badge status="success" text="Завершено" />;
            case 'PEND':
                return <Badge status="warning" text="Ожидание" />;
            case 'PAID':
                return <Badge status="warning" text="Ожидание" />;
            case 'CANCEL':
                return <Badge status="error" text="Отменен" />;
            case 'CANCEL_SYS':
                return <Badge status="error" text="Отменен с." />;
            default:
                return <Badge status="default" text={status} />;
        }
    };
    const handleOpenModal = (item) => {
        setSelectedItem(item);
        setModalVisible(true);
    };
    const OpenOrderRender = (_, item) => (

        <div
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenModal(item)}
        >
            <BankImageWrapper bankName={item.bankName}/>
        </div>
    );
    const columns = [
        {
            title: 'Дата',
            width: '20%',
            fixed: 'left',
            dataIndex: 'tsCreate',
            key: 'tsCreate',
            render: tsCreate => new Date(tsCreate).toLocaleString(),
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'team',
            key: 'team',
        },
        {
            title: 'Ордер',
            width: '10%',
            dataIndex: 'tradeType',
            key: 'orderNumber',
            render: (tradeType, record) => (
                <Tag  color={tradeType === 'BUY' ? '#87d068' : '#f50'}>
                    {record.orderNumber}
                </Tag>
            ),
        },
        {
            title: 'Статус',
            width: '15%',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            render: getStatusBadge,
        },

        {
            title: 'Сумма',
            width: '10%',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            render: totalPrice => Number(totalPrice).toLocaleString('ru-RU') + ' ₽',
        },
        {
            title: 'Карта',
            width: '10%',
            dataIndex: 'payCard',
            key: 'payCard',
        },
        {
            title: 'Банк',
            width: '7%',
            dataIndex: 'bankName',
            key: 'bankName',
            render: OpenOrderRender,
        },

    ];
    const paginationConfig = {
        current: currentPage,
        total: totalOrders,
        pageSize: itemsPerPage,
        onChange: (page, pageSize) => {
            handlePageClick(page);
            setItemsPerPage(pageSize);
        },
        showSizeChanger: true,
        showQuickJumper: true, // Добавляем функциональность Quick Jumper

    };
    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedItem(null);
    };
    return (
        <>
            <Table
                dataSource={tableData}
                loading={loadingTable}
                columns={columns}
                pagination={paginationConfig}
                scroll={{ x: 'max-content', y: '68vh' }}
                sticky
            />
            <OrderModal
                visible={modalVisible}
                onClose={handleCloseModal}
                team={selectedItem?.team}
                orderNumber={selectedItem?.orderNumber}
            />
        </>
    );
}

export default OrderTable;
