import React, { useState, useEffect } from 'react';
import { Skeleton, Card, Row, Col, Button, Modal, Input } from 'antd';
import { ReloadOutlined, SwapOutlined } from '@ant-design/icons';
import { postBrokerInfo, postConvertBalance, postUsdtBalance,getTronBalance } from "../../../config/crm/apiBroker";
import { formatNumber } from "../../../components/numericFunc";
import { addToast } from "../../../components/Notification/Toast";
const getBalanceColor = (balance) => {
    if (balance >= 30000) {
        return 'green';
    } else if (balance >= 10000) {
        return '#F3A505'; // Желтый цвет
    } else {
        return 'red';
    }
};
const cardInfoList = [
    { key: 'username', title: 'Аккаунт', formatter: value => value },
    { key: 'conversion', title: 'Конверсия', formatter: value => `${value} %` },
    { key: 'dailyTurnover', title: 'Оборот', formatter: formatNumber },
    { key: 'course', title: 'Курс', formatter: formatNumber },
    {
        key: 'balance',
        title: 'Баланс',
        formatter: (balance, newBalance) => {
            const balanceFormatted = formatNumber(balance);
            const newBalanceFormatted = newBalance ? formatNumber(newBalance, true, "$") : "N/A";
            return `${balanceFormatted} (${newBalanceFormatted})`;
        }
    },
    { key: 'usdtBalance', title: 'USDT на балансе', formatter: (balance) => formatNumber(balance, true, "$") },
    {
        key: 'tronBalance',
        title: 'TRON Баланс',
        formatter: (tronBalances) => {
            // Если массив пустой, отображаем сообщение
            if (!tronBalances || tronBalances.length === 0) {
                return <div style={{ color: 'gray' }}>Нет данных по TRON балансу</div>;
            }

            // Рендерим данные с проверкой цвета
            return tronBalances.map(tron => (
                <div key={tron.name} style={{ color: getBalanceColor(tron.balance) }}>
                    {tron.name}: {formatNumber(tron.balance, true, "$")}
                </div>
            ));
        }
    }

];

const InfoCard = ({ title, value, loading, size, right }) => {
    return (
        <Card size={size} title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>{title}{right}</div>}>
            {loading ? (
                <Skeleton.Input active={true} size={'small'} />
            ) : (
                <span>{value}</span>
            )}
        </Card>
    );
};

const BrokerInfo = ({ team }) => {
    const [loading, setLoading] = useState(true);
    const [loadingUsdt, setLoadingUsdt] = useState(false);
    const [tronLoading, setTronLoading] = useState(false);

    const [data, setData] = useState({
        username: '',
        conversion: null,
        dailyTurnover: null,
        course: null,
        balance: null,
        usdtBalance: null,
        newBalance: null,
        tronBalances: []

    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [conversionAmount, setConversionAmount] = useState('');

    useEffect(() => {
        fetchData();
    }, [team]);

    const fetchData = async () => {
        const body = { team };
        try {
            setLoading(true);

            // Выполняем оба запроса параллельно
            const [brokerInfoResponse, usdtBalanceResponse, tronBalanceResponse] = await Promise.all([
                postBrokerInfo(body),
                postUsdtBalance(body),
                getTronBalance()

            ]);

            // Проверяем успешность обоих запросов
            if (brokerInfoResponse.code === 1000 && usdtBalanceResponse.code === 1000 && tronBalanceResponse.code === 1000) {
                const balance = brokerInfoResponse.data.balance;
                const course = brokerInfoResponse.data.course_data.course;
                const newBalance = course > 0 ? balance / course : null;

                // Обновляем данные состояния
                setData({
                    username: brokerInfoResponse.data.username,
                    conversion: brokerInfoResponse.data.invoice_conversion ?? 100,
                    dailyTurnover: brokerInfoResponse.data.daily_statistic.daily_invoice_turnover,
                    course: course,
                    balance: balance,
                    usdtBalance: usdtBalanceResponse.data.balance,
                    newBalance: newBalance,
                    tronBalances: tronBalanceResponse.data // Сохраняем данные TRON баланса

                });
            } else {
                // Обработка ошибки при некорректных данных
                await addToast('warning', 'Ошибка', 'Не удалось обновить данные');
            }
        } catch (error) {
            // Обработка ошибки при выполнении запроса
            await addToast('warning', 'Внимание', `${error.message}`);
        } finally {
            // Сбрасываем состояние загрузки
            setLoading(false);
        }
    };
    const handleRefreshTronBalance = async () => {
        try {
            setTronLoading(true);
            const body = { team };
            const tronBalanceResponse = await getTronBalance(body);

            if (tronBalanceResponse.code === 1000) {
                setData(prevData => ({
                    ...prevData,
                    tronBalances: tronBalanceResponse.data // Обновляем только TRON баланс
                }));
            } else {
                await addToast('warning', 'Ошибка', 'Не удалось обновить TRON Баланс');
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
        } finally {
            setTronLoading(false);
        }
    };
    const handleRefreshUsdtBalance = async () => {
        try {
            setLoadingUsdt(true);
            const body = { team };
            const usdtBalanceResponse = await postUsdtBalance(body);

            if (usdtBalanceResponse.code === 1000) {
                setData(prevData => ({
                    ...prevData,
                    usdtBalance: usdtBalanceResponse.data.balance // Обновляем только usdtBalance
                }));
            } else {
                await addToast('warning', 'Ошибка', 'Не удалось обновить USDT Баланс');
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
        } finally {
            setLoadingUsdt(false);
        }
    };

    const handleOpenConversionModal = () => {
        setConversionAmount('');
        setIsModalVisible(true);
    };

    const handleConversion = async () => {
        try {
            const amount = parseFloat(conversionAmount);
            if (isNaN(amount) || amount <= 0) {
                await addToast('warning', 'Ошибка', 'Введите корректное значение');
                return;
            }
            const response = await postConvertBalance({ team, amount });
            if (response.code === 1000) {
                await addToast('success', 'Успешно', 'Конвертация выполнена');
                fetchData(); // Обновление данных после конвертации
            } else {
                await addToast('warning', 'Ошибка', 'Конвертация не удалась');
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
        } finally {
            setIsModalVisible(false);
        }
    };

    return (
        <Row gutter={[16, 16]}>
            {cardInfoList.map(cardInfo => (
                <Col span={8} key={cardInfo.key}>
                    <InfoCard
                        title={cardInfo.title}
                        value={cardInfo.key === 'balance'
                            ? cardInfo.formatter(data.balance, data.newBalance)
                            : cardInfo.key === 'usdtBalance'
                                ? cardInfo.formatter(data.usdtBalance)
                                : cardInfo.key === 'tronBalance'
                                    ? cardInfo.formatter(data.tronBalances)
                                    : cardInfo.formatter(data[cardInfo.key])
                        }
                        loading={loading || (loadingUsdt && cardInfo.key === 'usdtBalance') || (tronLoading && cardInfo.key === 'tronBalance')}
                        size="small"
                        right={cardInfo.key === 'usdtBalance' ? (
                            <>
                                <Button type="text" icon={<ReloadOutlined />} onClick={handleRefreshUsdtBalance} />
                                <Button type="text" icon={<SwapOutlined />} onClick={handleOpenConversionModal} />
                            </>
                        ) : cardInfo.key === 'tronBalance' ? (
                            <Button type="text" icon={<ReloadOutlined />} onClick={handleRefreshTronBalance} />
                        ) : null}
                    />
                </Col>
            ))}
            <Modal
                title="Конвертация"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={handleConversion}
            >
                <Input
                    type="number"
                    placeholder="Введите сумму"
                    value={conversionAmount}
                    onChange={e => setConversionAmount(e.target.value)}
                    addonAfter={<Button type="link" onClick={() => setConversionAmount(String(data.balance))}>Все</Button>}
                />
            </Modal>
        </Row>
    );
};

export default BrokerInfo;
