import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Button, Table, Select, Input, Space, Spin } from 'antd';
import { postDashboardOrders, postDashboardAccept } from "../../../config/crm/apiBroker";
import { formatNumber } from "../../../components/numericFunc";
import { addToast } from "../../../components/Notification/Toast";
import { ReloadOutlined } from "@ant-design/icons";
import './TableStyles.css'; // Импорт стилей

const { Option } = Select;
const refreshInterval = 15; // Интервал автообновления в секундах
const getTypeName = typeID => {
    switch (typeID) {
        case 1: return 'Карта';
        case 2: return 'СБП';
        case 3: return 'Мобильный';
        default: return 'Все';
    }
};
// Функция для создания фильтров
const createQuickFilters = (bankId, bankName, amounts, color, textColor = 'white') =>
    amounts.map((amount, index) => ({
        bankId,
        amount: amount.from ? amount.from * 1000 : null,
        amountTo: amount.to ? amount.to * 1000 + 999 : null,
        label: `${bankName} (${amount.from}k${amount.from ? '' : '+'})`,
        color,
        textColor,
    }));

// Quick Filters
const quickFilters1 = createQuickFilters(1, "Сбер", [
    { from: 1, to: 9 },
    { from: 10, to: 19 },
    { from: 20, to: 24 },
    { from: 25, to: 29 },
    { from: 30, to: 34 },
    { from: 35, to: 39 },
    { from: 40, to: 49 },
    { from: 50, to: null }
], '#24a319');

const quickFilters2 = createQuickFilters(7, "Тинь", [
    { from: 1, to: 9 },
    { from: 10, to: 19 },
    { from: 20, to: 24 },
    { from: 25, to: 29 },
    { from: 30, to: 34 },
    { from: 35, to: 39 },
    { from: 40, to: 49 },
    { from: 50, to: null }
], '#f2f066', 'black');
const banks = [
    { id: 1, name: "Сбербанк" },
    { id: 7, name: "Тинькофф Банк" },
    { id: 4, name: "Райффайзен Банк" },
    { id: 8, name: "Альфа Банк" },
    { id: 13, name: "ВТБ" },
];
const DashboardBroker = ({ team }) => {
    const [loading, setLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [selectedBank, setSelectedBank] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [amountFrom, setAmountFrom] = useState(null);
    const [amountTo, setAmountTo] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [scrollY, setScrollY] = useState(window.innerHeight);
    const [countdown, setCountdown] = useState(refreshInterval);

    // Ref для хранения текущих значений
    const filtersRef = useRef({ selectedBank, selectedType, amountFrom, amountTo, currentPage, pageSize });

    useEffect(() => {
        filtersRef.current = { selectedBank, selectedType, amountFrom, amountTo, currentPage, pageSize };
    }, [selectedBank, selectedType, amountFrom, amountTo, currentPage, pageSize,selectedType]);

    // Получение заявок
    const fetchApplications = useCallback(async () => {
        const { selectedBank, selectedType, amountFrom, amountTo, currentPage, pageSize } = filtersRef.current;
        const body = { team, page: currentPage, size: pageSize };

        if (selectedBank) body.bankId = selectedBank;
        if (selectedType) body.typeID = selectedType;
        if (amountFrom !== null) body.amountFrom = parseInt(amountFrom);
        if (amountTo !== null) body.amountTo = parseInt(amountTo);

        try {
            setLoading(true);
            const response = await postDashboardOrders(body);
            if (response.code === 1000) {
                setApplications(response.data);
                setTotal(response.total);
            }
        } catch (error) {
            await addToast('error', 'Ошибка', 'Загрузки заявок');
        } finally {
            setLoading(false);
        }
    }, [team]);

    useEffect(() => {
        fetchApplications();
    }, [selectedBank, amountFrom,amountTo, currentPage, pageSize, selectedType, fetchApplications]);

    // Автообновление
    useEffect(() => {
        let intervalId, countdownInterval;

        if (autoRefresh) {
            intervalId = setInterval(() => {
                fetchApplications();
                setCountdown(refreshInterval);
            }, refreshInterval * 1000);

            countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => (prevCountdown > 0 ? prevCountdown - 1 : refreshInterval));
            }, 1000);
        } else {
            setCountdown(refreshInterval);
        }

        return () => {
            clearInterval(intervalId);
            clearInterval(countdownInterval);
        };
    }, [autoRefresh, fetchApplications]);

    // Стилизация строк таблицы
    const rowClassName = (record) => record.isGroup ? 'grouped-row' : 'ungrouped-row';

    // Изменение таблицы (пагинация)
    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    // Обработчики изменений фильтров
    const handleFilterChange = (setter) => (value) => setter(value);

    // Обработка принятия заявки
    const handleAcceptApplication = async (orderNumber) => {
        try {
            const response = await postDashboardAccept({ team, orderNumber });
            if (response.code === 1000) {
                await addToast('success', 'Успех', 'Заявка принята успешно');
                await fetchApplications();
            } else {
                await addToast('error', 'Ошибка', 'Не удалось принять заявку');
            }
        } catch (error) {
            await addToast('error', 'Ошибка', 'Не удалось принять заявку');
        }
    };

    // Обработчик на правый клик для автообновления
    const handleRightClick = useCallback((event) => {
        event.preventDefault();
        setAutoRefresh(!autoRefresh);
    }, [autoRefresh]);

    // Столбцы таблицы
    const columns = [
        {
            title: 'Банк',
            dataIndex: 'receiverBankName',
            key: 'receiverBankName',
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => formatNumber(record.amount, false),
            sorter: (a, b) => a.amount - b.amount,
            align: 'right',
        },
        {
            title: 'Тип',
            dataIndex: 'typeID',
            key: 'typeID',
            render: getTypeName,
            align: 'center',
        },
        {
            title: 'Получатель',
            dataIndex: 'receiver',
            key: 'receiver',
        },
        {
            title: 'Действие',
            key: 'action',
            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={() => handleAcceptApplication(record.id)}
                >
                    Взять заявку
                </Button>
            ),
            align: 'center',
        },
    ];

    // Быстрая фильтрация
    const applyQuickFilter = (bankId, amount, amountTo) => {
        setSelectedBank(bankId);
        setAmountFrom(amount);
        setAmountTo(amountTo);
        setCurrentPage(1);
    };

    // Кнопка обновления
    const UpdateButton = () => (
        <Button
            type="primary"
            style={{width: '100%'}}
            onContextMenu={handleRightClick}
            onClick={() => {
                setLoading(true);
                fetchApplications();
            }}
            disabled={loading}
            icon={loading ? <Spin /> : <ReloadOutlined />}
        >
            Обновить {autoRefresh ? `(${countdown} сек)` : ""}
        </Button>
    );

    const QuickFilterButtons = ({ quickFilters }) => {
        return (
            <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
                {quickFilters.map((filter, index) => (
                    <Col
                        key={index}
                        xs={24} // Полная ширина на маленьких экранах
                        sm={12} // Половина ширины на средних экранах
                        md={6} // Треть ширины на больших экранах
                        lg={3} // Четверть ширины на очень больших экранах
                    >
                        <Button
                            type="primary"
                            block
                            style={{
                                backgroundColor: filter.color,
                                borderColor: filter.color,
                                color: filter.textColor || 'white',
                            }}
                            onClick={() => applyQuickFilter(filter.bankId, filter.amount, filter.amountTo)}
                        >
                            {filter.label}
                        </Button>
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        <>
            <Row gutter={[16, 16]} style={{ marginBottom: 16, flexWrap: 'nowrap' }}>
                <Col xs={24} sm={12} md={6} lg={5}>
                    <Select
                        placeholder="Выберите банк"
                        style={{ width: '100%' }}
                        value={selectedBank}
                        onChange={handleFilterChange(setSelectedBank)}
                        allowClear
                    >
                        <Option value={null}>Все</Option>
                        {banks.map(bank => (
                            <Option key={bank.id} value={bank.id}>{bank.name}</Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <Select
                        placeholder="Выберите тип"
                        style={{ width: '100%' }}
                        value={selectedType}
                        onChange={handleFilterChange(setSelectedType)}
                        allowClear
                    >
                        <Option value={null}>Все</Option>
                        <Option value={1}>Карта</Option>
                        <Option value={2}>СБП</Option>
                        <Option value={3}>Мобильный</Option>
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={6} lg={5}>
                    <Input
                        placeholder="Сумма от"
                        type="number"
                        style={{ width: '100%' }}
                        value={amountFrom !== null ? amountFrom : ''}
                        onChange={(e) => setAmountFrom(e.target.value ? parseInt(e.target.value) : null)}
                        onBlur={fetchApplications}
                        onPressEnter={fetchApplications}
                        allowClear
                    />
                </Col>
                <Col xs={24} sm={12} md={6} lg={5}>
                    <Input
                        placeholder="Сумма до"
                        type="number"
                        style={{ width: '100%' }}
                        value={amountTo !== null ? amountTo : ''}
                        onChange={(e) => setAmountTo(e.target.value ? parseInt(e.target.value) : null)}
                        onBlur={fetchApplications}
                        onPressEnter={fetchApplications}
                        allowClear
                    />
                </Col>
                <Col xs={24} sm={12} md={6} lg={5}>
                    <UpdateButton/>
                </Col>
            </Row>
            <QuickFilterButtons quickFilters={quickFilters1} />
            <QuickFilterButtons quickFilters={quickFilters2} />
            <Table
                columns={columns}
                dataSource={applications}
                loading={loading}
                rowKey="id"
                locale={{ emptyText: 'Нет доступных заявок' }}
                pagination={{
                    current: currentPage,
                    pageSize,
                    total,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100'],
                }}
                scroll={{ y: scrollY ? `${scrollY - 320}px` : undefined }}
                onChange={handleTableChange}
                rowClassName={rowClassName}
            />
        </>
    );
};

export default DashboardBroker;
