import React, { useState } from 'react';
import { Tabs, Button } from 'antd';
import DesInfo from './DesInfo';
import DesAdv from "./DesAdvPage";
import SessionDes from "./SessionDes";

const { TabPane } = Tabs;

const DesGeneral = () => {
    const [activeTab, setActiveTab] = useState('info'); // Для отслеживания активной вкладки
    // Создаем объект состояния для ключей перерендера каждого таба
    const [reloadKeys, setReloadKeys] = useState({
        info: 0,
        adv: 0,
        session: 0
    });

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleRefresh = () => {
        // Обновляем ключ только для активного таба
        setReloadKeys(prevKeys => ({
            ...prevKeys,
            [activeTab]: prevKeys[activeTab] + 1
        }));
    };

    return (
        <div>
            <Tabs activeKey={activeTab} onChange={handleTabChange} tabBarStyle={{ marginBottom: '16px' }} tabBarExtraContent={{
                right: (
                    <Button onClick={handleRefresh} style={{ float: 'right', marginBottom: '16px' }}>Обновить</Button>
                ),
            }}>
                <TabPane tab="Информация" key="info">
                    <DesInfo key={reloadKeys.info} team={localStorage.getItem('team')} />
                </TabPane>
                <TabPane tab="Объявления" key="adv">
                    <DesAdv key={reloadKeys.adv} team={localStorage.getItem('team')} />
                </TabPane>
                <TabPane tab="Сессия" key="session">
                    <SessionDes key={reloadKeys.session} team={localStorage.getItem('team')} />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default DesGeneral;
