import React, { useState, useEffect } from 'react';
import {Card, List, message, Modal, Skeleton, Spin, Tooltip, Radio, Form, Badge} from 'antd';
import {postBrokerOrderInfo, postBrokerViewImg, postCancelOrder} from "../../../config/crm/apiBroker";
import {handleCopy} from "../../../components/LogicComponents";
import {FileTextOutlined, InfoCircleOutlined, LockOutlined} from "@ant-design/icons";
import {formatNumber} from "../../../components/numericFunc";
import {formattedDate} from "../../../components/dateFunc";
import {ReverseTimer} from "../../../components/LogicComponents";
import {addToast} from "../../../components/Notification/Toast";
import {crmAgentInfo, crmOrderInfo, crmOrdersInfo,crmOrderAdd} from "../../../config/crm/apiNote";
import {NotesModal} from "../../../components/OrderDetail/OrderHistoryModal";

function renderInfoItem(label, value, tooltip = null, copy = null, defaultValue = '') {
    const content = (
        <span className="list-item-value">
            {copy ? (
                <span style={{ cursor: 'pointer' }} onClick={() => handleCopy(copy)}>{value}</span>
            ) : (
                value || defaultValue
            )}
        </span>
    );

    return (
        <List.Item className="list-item">
            <span className="list-item-label">{label}:</span>
            {tooltip ? (
                <Tooltip placement="left" title={tooltip}>
                    {content}
                </Tooltip>
            ) : (
                content
            )}
        </List.Item>
    );
}




const renderSkeletonItem = (leftWidth, rightWidth,label) => (
    <List.Item className="list-item">
        <div style={{ width: leftWidth, float: 'left' }}>
            <span className="list-item-label">{label}:</span>
        </div>
        <div style={{ width: rightWidth, float: 'right' }}>
            <Skeleton.Input size={14} active title={{ width: '100%' }} block={true} />
        </div>
    </List.Item>
);



function SkeletonCard() {
    const skeletonWidths = [
        { label: 'Дата', left: '20%', right: '50%' },
        { label: 'Ордер', left: '20%', right: '50%' },
        { label: 'Актив', left: '20%', right: '50%' },
        { label: '.....', left: '50%', right: '50%' },
        { label: 'Сумма', left: '20%', right: '50%' },
    ];

    return (
        <Card
            size="small"
            bordered={false}
            style={{ height: '100%' }}

            title={
                <div style={{ cursor: 'pointer' }}>
                    Информация
                </div>
            }
        >
            <Spin tip="Загрузка...">
                <List>
                    {skeletonWidths.map((widths, index) =>
                        // Добавляем уникальный ключ для каждого элемента списка
                        <React.Fragment key={index}>
                            {renderSkeletonItem(widths.left, widths.right, widths.label)}
                        </React.Fragment>
                    )}
                    <List.Item>
                        <button className={`btn btn-warning btn-sm w-100 disabled`}>
                            <LockOutlined />
                        </button>
                    </List.Item>
                </List>
            </Spin>
        </Card>
    );
}

const createStatusButton = (status, item, text, className) => (
    <button className={`btn btn-${className} btn-sm w-100`}>{text}</button>
);
const OrderModal = ({ visible, onClose, team, orderNumber,fetchOrders }) => {
    const [orderInfo, setOrderInfo] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);
    const [isImageVisible, setIsImageVisible] = useState(false);
    const [loading, setLoading] = useState(false)
    const [isReasonModalVisible, setIsReasonModalVisible] = useState(false);
    const [reason, setSelectedReason] = useState(3); // Значение по умолчанию
    const [orderNotesStatus, setOrderNotesStatus] = useState(false);
    const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);

    const fetchInfoNote = async () => {
        try {
            const orderNoteResponse = await crmOrderInfo({ orderNumber });
            setOrderNotesStatus(orderNoteResponse.data.length > 0);
        } catch (error) {
            console.error("Error fetching order info:", error);
        }
    };
    const handleReasonSubmit = async () => {
        const body = { team, orderNumber,reason };
        setLoading(true)
        try {
            const response = await postCancelOrder(body);
            if (response.code === 1000) {
                message.success('Ордер успешно отменен!');

            } else {
                message.error('Не удалось отменить ордер');
            }
        } catch (error) {
            await addToast('warning', 'Ошибка', `${error.message}`);
            message.error('Не удалось отменить ордер');
        } finally {
            fetchOrders()
            setIsReasonModalVisible(false);
        }
    };
    useEffect(() => {
        if (visible && team && orderNumber) {
            fetchOrderInfo();
        }
    }, [visible, team, orderNumber]);

    const fetchOrderInfo = async () => {
        setOrderNotesStatus(false);
        setIsNotesModalVisible(false);
        try {
            const response = await postBrokerOrderInfo({ team, orderNumber });
            if (response.code === 1000) {
                setOrderInfo(response.data);
            } else {
                console.error('Error fetching order info:', response);
            }
            fetchInfoNote()
        } catch (error) {
            await addToast('warning', 'Ошибка', `${error.message}`);
        }
    };

    const handleViewImg = async (team, orderNumber) => {
        const body = { team, orderNumber };
        setLoading(true)
        try {
            const response = await postBrokerViewImg(body);
            if (response.code === 1000) {
                const base64Data = response.data; // Предполагается, что response.data содержит base64 строку
                const imgUrl = `data:image/png;base64,${base64Data}`;
                setImgUrl(imgUrl);
                setIsImageVisible(true);
            } else {
                message.error('Не удалось загрузить изображение');
            }
        } catch (error) {
            await addToast('warning', 'Ошибка', `${error.message}`);

            message.error('Не удалось загрузить изображение');
        } finally {
            setLoading(false)
        }
    };

    const StatusColumnRender = (status, item) => {
        switch (status) {
            case 'COMPLETE':
                return (
                    <button  className={`btn btn-success btn-sm w-100 ${loading ? 'disabled' : ''}`}
                             onClick={() => handleViewImg(item.team, item.orderNumber)}
                    >
                        Просмотр файла
                    </button>
                );
            case 'CANCEL':
                return createStatusButton(status, item, 'Отменен', 'warning');
            case 'APPEAL':
                return createStatusButton(status, item, 'Апелляция', 'danger');
            case 'PEND':
                return (
                    <>
                        <button className="btn btn-outline-secondary btn-sm w-100">
                            Принять {' '}
                            <ReverseTimer date={item.tsNotifyPendEnd}/>
                        </button>

                    </>
                );
            case 'PAID':
                return (
                    <>
                        <button className="btn btn-outline-secondary btn-sm w-100"
                                style={{ marginBottom: '8px' }} // Добавляем отступ снизу
                        >
                            Подтвердить {' '}
                            <ReverseTimer
                                date={item.paidStatus === false ? item.tsCreate + 39 * 60 * 1000 : item.tsNotifyPaidEnd}/>
                        </button>
                        <br/>
                        <button
                            className="btn btn-danger btn-sm w-100"
                            onClick={() => setIsReasonModalVisible(true)}
                        >
                            Отменить
                        </button>
                        <Modal
                            title="Выберите причину отмены"
                            open={isReasonModalVisible}
                            onCancel={() => setIsReasonModalVisible(false)}
                            onOk={handleReasonSubmit}
                            okText="Ок"
                            cancelText="Отмена"
                        >
                            <Form>
                                <Form.Item>
                                    <Radio.Group
                                        value={reason}
                                        onChange={(e) => setSelectedReason(e.target.value)}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        <Radio value={3} style={{ marginBottom: '8px' }}>Красный блок</Radio>
                                        <Radio value={5} style={{ marginBottom: '8px' }}>Нет банка</Radio>
                                        <Radio value={6} style={{ marginBottom: '8px' }}>Проблема у банка</Radio>
                                        <Radio value={7}>Проблема на моей стороне</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </>

                );
            default:
                return null;
        }
    };

    return (
        <Modal open={visible} onCancel={onClose} onOk={onClose}>
            {orderInfo ? (
                <Card
                    size="small"
                    bordered={false}
                    style={{height: '100%'}}
                    title={
                    <>
                        <div style={{cursor: 'pointer'}}>
                            Информация {orderInfo.bankName} {formatNumber(orderInfo.totalPrice, true)}
                            <Tooltip title="Пометка к ордеру">
                                <FileTextOutlined
                                    style={{ color: orderNotesStatus ? 'green' : 'gray', marginLeft: 8 }}
                                    onClick={() => setIsNotesModalVisible(true)}
                                />
                            </Tooltip>
                        </div>

                    </>

                    }
                >
                    <List>
                        {renderInfoItem('Заявка', orderInfo.advNumber)}
                        {renderInfoItem('Ордер', orderInfo.orderNumber, false, orderInfo.orderNumber)}
                        {renderInfoItem('Дата заявки', formattedDate(orderInfo.tsCreate))}
                        {orderInfo.tsPaid && renderInfoItem('Дата принятия', formattedDate(orderInfo.tsPaid))}
                        {orderInfo.tsPend && renderInfoItem('Дата подтверждения', formattedDate(orderInfo.tsPend))}
                        {renderInfoItem('Карта', orderInfo.payCard, null, orderInfo.payCard)}
                        {renderInfoItem('Сумма', formatNumber(orderInfo.totalPrice, true), null, orderInfo.totalPrice)}
                        {StatusColumnRender(orderInfo.orderStatus, orderInfo)}
                    </List>
                    <Modal
                        title={`Ордер на ${formatNumber(orderInfo.totalPrice, true)} Карта ${orderInfo.payCard}`}
                        open={isImageVisible}
                        onCancel={() => setIsImageVisible(false)}
                        footer={null}
                    >
                        {imgUrl && <img src={imgUrl} alt="Order Image" style={{ width: '100%' }} />}
                    </Modal>
                </Card>
            ) : (
                <SkeletonCard />
            )}
            <NotesModal
                visible={isNotesModalVisible}
                onClose={() => setIsNotesModalVisible(false)}
                orderNumber={orderNumber}
            />
        </Modal>
    );
};

export default OrderModal;