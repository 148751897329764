import React, {useEffect, useState} from 'react';
import {Button, Row, InputNumber, Switch, Col, Tooltip,Modal} from 'antd';
import { ExportOutlined ,DownloadOutlined} from '@ant-design/icons';
import {MarketIcon} from "../../../../components/CommonComponents";
import MerchStore from "../../../../state/MerchStore";
import BotsStore from "../../../../state/BotsStore";
import {observer} from "mobx-react";
import {CustomTableVh, CustomCardRow} from "../../../../Layouts/Card/CustomCard";
import {assetsOptions} from "../../../../config/constants";
import PriceStore from "../../../../state/PriceStore";
import glassPriceStore from "../../../../state/GlassPriceStore";



const AddBots = observer(() => {
    const [filteredInfo, setFilteredInfo] = useState({});
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [activeTradeType, setActiveTradeType] = useState(null);
    const [selectedMarkets, setSelectedMarkets] = useState(null);

    const marketFilter =  MerchStore.getAllMarketsValue()
    const userNickNameFilter = MerchStore.getAllNickNameValue()
    const handleButtonFilterMarket = (marketNumber) => {
        console.log(handleButtonFilterMarket)
        if (selectedMarkets === marketNumber) {
            setFilteredInfo({ ...filteredInfo, marketNumber: null });
            setSelectedMarkets(null);
        } else {
            // Установка нового фильтра
            setFilteredInfo({ ...filteredInfo, marketNumber: [marketNumber] });
            setSelectedMarkets(marketNumber);
        }
    };
    useEffect(() => {
        BotsStore.loadingListBots(); // Загрузка списка ботов при монтировании компонента
    }, []);

    useEffect(() => {
        handleButtonFilterMarket(PriceStore.marketActive)
    }, [PriceStore.marketActive]);

    useEffect(() => {
        handleButtonFilterChangeMaga(glassPriceStore.asset)
    }, [glassPriceStore.asset]);


    const handleButtonFilterChangeMaga = (asset) => {
        setSelectedAssets(prev => {
            const newSelectedAssets = [asset];
            setFilteredInfo({ ...filteredInfo, asset: newSelectedAssets });

            return newSelectedAssets;
        });
    };
    const handleButtonFilterChange = (asset) => {
        setSelectedAssets(prev => {
            const newSelectedAssets = prev.includes(asset) ? prev.filter(a => a !== asset) : [...prev, asset];
            setFilteredInfo({ ...filteredInfo, asset: newSelectedAssets });

            return newSelectedAssets;
        });
    };




    const handleButtonFilterTradeType = (tradeType) => {
        if (activeTradeType === tradeType) {
            setFilteredInfo({ ...filteredInfo, tradeType: null });
            setActiveTradeType(null);
        } else {
            // Установка нового фильтра
            setFilteredInfo({ ...filteredInfo, tradeType: [tradeType] });
            setActiveTradeType(tradeType);
        }
    };




    const getRowClassName = (record) => {
        return record.tradeType === 'SELL' ? 'row-red' : 'row-green';
    };


    const columns = [
        {
            title: '',
            dataIndex: 'marketNumber',
            width: '8%',
            filters: marketFilter,
            filteredValue: filteredInfo.marketNumber || null,
            onFilter: (value, record) => record.marketNumber === value,
            render: market => <MarketIcon market={market} />,
        },
        {
            title: 'Ник',
            width: '18%',
            dataIndex: 'nickName',
            key: 'nickName',
            filters: userNickNameFilter,
            filteredValue: filteredInfo.nickName || null,
            onFilter: (value, record) => record.nickName.startsWith(value),
            render: (_, record) => (
                <Tooltip placement="bottom" title={record.pay}>
                    {record.nickName}
                </Tooltip>
            )

        },
        {
            title: 'МинМакс',
            width: '20%',
            dataIndex: 'priceMinMax',
            key: 'priceMinMax',
            sorter: (a, b) => a.priceMinMax - b.priceMinMax,
            render: (_, record) => (
                <InputNumber
                    min={0}
                    step={0.01}
                    value={record.priceMinMax}
                    size={'small'}
                    disabled={record.autoCourse}
                    onChange={(newPrice) => BotsStore.updateBotMinPrice(record.adsNumber, newPrice)}
                />
            )
        },
        {
            title: 'Бот лимит',
            width: '20%',
            dataIndex: 'botLimit',
            key: 'botLimit',
            sorter: (a, b) => a.botLimit - b.botLimit,
            render: (_, record) => (
                <InputNumber
                    min={0}
                    step={500}
                    value={record.botLimit}
                    size={'small'}
                    onChange={(newPrice) => BotsStore.updateBotLimit(record.adsNumber, newPrice)}

                />
            )
        },
        {
            title: 'Авто',
            width: '12%',
            key: 'autoCourse',
            dataIndex: 'autoCourse',
            filteredValue: filteredInfo.autoCourse || null,

            filters: [
                { text: 'Включено', value: true },
                { text: 'Выключено', value: false },
            ],
            onFilter: (value, record) => record.autoCourse === value,

            render: (_, record) => (
                <Switch
                    size={'small'}
                    checked={record.autoCourse}
                    onClick={() => BotsStore.toggleAuto(record.adsNumber, !record.autoCourse)}
                />
            )
        },
        {
            title: 'Актив',
            width: '14%',
            key: 'asset',
            dataIndex: 'asset',
            filters: assetsOptions,
            filteredValue: filteredInfo.asset || null,
            onFilter: (value, record) => record.asset === value,

        },
        {
            title: '',
            width: '10%',
            key: 'isRunning',
            dataIndex: 'isRunning',
            filteredValue: filteredInfo.isRunning || null,
            filters: [
                { text: 'Включено', value: true },
                { text: 'Выключено', value: false },
            ],
            onFilter: (value, record) => record.isRunning === value,
            render: (_, record) => (
                <Switch
                    size={'small'}
                    checked={record.isRunning}
                    onClick={() => BotsStore.toggleBot(record.adsNumber, !record.isRunning)}
                />
            )

        },
        {
            title: '',
            width: '20%',
            key: 'tradeType',
            dataIndex: 'tradeType',
            filteredValue: filteredInfo.tradeType || null,
            filters: [
                { text: "Покупка", value: "BUY" },
                { text: "Продажа", value: "SELL" },
            ],
            onFilter: (value, record) => record.tradeType.startsWith(value),
            render: (_, record) => (
                <Button
                    size={"small"}
                    type="primary"
                    icon={<ExportOutlined />}
                    onClick={() => BotsStore.btnQuickUpdate(record.adsNumber)}
                    onContextMenu={(e) => {
                        e.preventDefault(); // Prevent the default context menu from appearing
                        Modal.confirm({
                            title: `Удалить бота ${record.asset}?`,
                            content: `Вы уверены, что хотите удалить бота у ${record.nickName}?`,
                            okText: 'Да',
                            cancelText: 'Нет',
                            onOk: () => BotsStore.btnDelete(record.adsNumber),
                        });
                    }}
                />
            )


        }
    ];
    const handleTableFilterChange = (pagination, filters) => {

        setFilteredInfo(filters);
        console.log(filters)
        // Если фильтр asset изменился в таблице, обновляем selectedAssets
        if (filters.asset) {
            setSelectedAssets(filters.asset);
        } else {
            // Если фильтр asset был сброшен в таблице, очищаем selectedAssets
            setSelectedAssets([]);
        }

        if (!filters.tradeType) {
            setActiveTradeType(null);
        }
    };

    return (
        <>
            <CustomCardRow
                children={
                <>
                    <Row gutter={[8, 16]} justify="start" wrap={false}>
                        <Col flex="auto" style={{ textAlign: 'left' }}>
                            <Button
                                size="small"
                                type={activeTradeType === "SELL" ? "primary" : "default"}
                                style={{ backgroundColor: activeTradeType === "SELL" ? "red" : undefined }}
                                onClick={() => handleButtonFilterTradeType("SELL")}
                            >
                                Продажа
                            </Button>
                        </Col>
                        {assetsOptions.map(option => (
                            <Col key={option.value}>
                                <Button
                                    size="small"
                                    type={selectedAssets.includes(option.value) ? "primary" : "default"}
                                    onClick={() => handleButtonFilterChange(option.value)}
                                    style={{ flex: 1, textAlign: 'center' }} // Растягивание кнопок
                                >
                                    {option.label}
                                </Button>
                            </Col>
                        ))}
                        <Col flex="auto" style={{ textAlign: 'right' }}>
                            <Button
                                size="small"
                                type={activeTradeType === "BUY" ? "primary" : "default"}
                                style={{ backgroundColor: activeTradeType === "BUY" ? "green" : undefined }}
                                onClick={() => handleButtonFilterTradeType("BUY")}
                            >
                                Покупка
                            </Button>
                        </Col>
                        <Col flex="auto" style={{ textAlign: 'right' }}>
                            <Button
                                size="small"
                                icon={<DownloadOutlined />}
                                onClick={() =>  BotsStore.loadingListBots()}
                            />
                        </Col>
                    </Row>
                </>

                }
                children2={
                <>
                    <CustomTableVh
                        vhPx = {210}
                        columns={columns}
                        dataSource={BotsStore.listBots}
                        pagination={false}
                        rowClassName={getRowClassName}
                        onChange={handleTableFilterChange}
                        rowKey={record => record.adsNumber}
                        scroll={{ x: 'max-content', y: '72vh' }}
                    />
                </>
                }
            />
        </>

    );
});

export default AddBots;