import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, InputNumber, Select, Switch, Form, Tooltip,Row,Col } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { addToast } from "../../../components/Notification/Toast";
import { postDesListAdv, postDesEditAdv, postDesDelAdv, postDesStatusAdv, postDesNewAdv } from "../../../config/crm/apiDes";

const DesAdv = ({ team }) => {
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState({}); // State to track status change loading
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [form] = Form.useForm();
    const fetchGroups = async () => {
        try {
            setLoading(true);
            const response = await postDesListAdv(team);
            if (response.code === 1000) {
                setGroups(response.data);
            }
        } catch (error) {
            await addToast('warning', 'Ошибка загрузки объявлений', `${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {


        fetchGroups();
    }, [team]);

    const handleCreateApplication = async () => {
        try {
            const values = await form.validateFields();
            const body = {
                team,
                payMethod: values.payMethod,
                minAmount: parseInt(values.minAmount, 10),
                maxAmount: parseInt(values.maxAmount, 10),
                surplus: parseInt(values.surplus, 10),
            };

            const response = await postDesNewAdv(body);
            if (response.code === 1000) {
                setModalVisible(false);
                form.resetFields();
                await addToast('success', 'Успех', 'Новое объявление успешно создано');
            } else {
                await addToast('error', 'Ошибка', `${response.msg}.`);
            }
        } catch (error) {
            await addToast('warning', 'Ошибка создания объявления', `${error.message}`);
        } finally {
            setTimeout(() => {
                fetchGroups();
            }, 3000);
        }
    };

    const handleDelete = async () => {
        if (!selectedGroup) return;

        try {
            const response = await postDesDelAdv({ team, advNumber: selectedGroup.advNumber });
            if (response.code === 1000) {
                await addToast('success', 'Успех', 'Объявление успешно удалено');
                setGroups((prevGroups) => prevGroups.filter((group) => group.advNumber !== selectedGroup.advNumber));
            } else {
                await addToast('error', 'Ошибка', `${response.msg}`);
            }
        } catch (error) {
            await addToast('warning', 'Ошибка удаления', `${error.message}`);
        } finally {
            setDeleteConfirmVisible(false);
            setSelectedGroup(null);
        }
    };

    const handleStatusChange = async (advNumber, status) => {
        setLoadingStatus((prev) => ({ ...prev, [advNumber]: true }));

        try {
            const response = await postDesStatusAdv({ team, advNumber, status: status });
            if (response.code === 1000) {
                await addToast('success', 'Успех', 'Статус объявления успешно изменен');
                setGroups((prevGroups) =>
                    prevGroups.map((group) =>
                        group.advNumber === advNumber ? { ...group, status } : group
                    )
                );
            } else {
                await addToast('error', 'Ошибка', `${response.msg}`);
                setGroups((prevGroups) =>
                    prevGroups.map((group) =>
                        group.advNumber === advNumber ? { ...group, status: !status } : group
                    )
                );
            }
        } catch (error) {
            await addToast('warning', 'Ошибка изменения статуса', `${error.message}`);
            setGroups((prevGroups) =>
                prevGroups.map((group) =>
                    group.advNumber === advNumber ? { ...group, status: !status } : group
                )
            );
        } finally {
            setLoadingStatus((prev) => ({ ...prev, [advNumber]: false }));
        }
    };

    const handleEdit = async (advNumber, minAmount, maxAmount, surplus) => {
        try {
            const body = {
                team,
                advNumber,
                minAmount: parseInt(minAmount, 10),
                maxAmount: parseInt(maxAmount, 10),
                surplus: parseInt(surplus, 10),
            };

            const response = await postDesEditAdv(body);
            if (response.code === 1000) {
                await addToast('success', 'Успех', 'Объявление успешно отредактировано');
                setGroups((prevGroups) =>
                    prevGroups.map((group) =>
                        group.advNumber === advNumber
                            ? { ...group, minAmount: body.minAmount, maxAmount: body.maxAmount, surplus: body.surplus }
                            : group
                    )
                );
            } else {
                await addToast('error', 'Ошибка', `${response.msg}`);
            }
        } catch (error) {
            await addToast('warning', 'Ошибка редактирования', `${error.message}`);
        }
    };

    const handleInputChange = (advNumber, field, value) => {
        setGroups((prevGroups) =>
            prevGroups.map((group) =>
                group.advNumber === advNumber ? { ...group, [field]: value } : group
            )
        );
    };

    const openDeleteConfirm = (record) => {
        setSelectedGroup(record);
        setDeleteConfirmVisible(true);
    };

    const columns = [
        {
            title: 'Банк',
            dataIndex: 'payMethod',
            key: 'payMethod',
            width: '20%',
        },
        {
            title: 'От',
            dataIndex: 'minAmount',
            key: 'minAmount',
            width: '20%',
            render: (text, record) => (
                <InputNumber
                    value={record.minAmount}
                    min={2000}
                    step={1000}
                    onChange={(value) => handleInputChange(record.advNumber, 'minAmount', value)}
                    changeOnWheel
                />
            ),
        },
        {
            title: 'До',
            dataIndex: 'maxAmount',
            key: 'maxAmount',
            width: '20%',
            render: (text, record) => (
                <InputNumber
                    value={record.maxAmount}
                    min={2000}
                    step={1000}
                    onChange={(value) => handleInputChange(record.advNumber, 'maxAmount', value)}
                    changeOnWheel
                />
            ),
        },
        {
            title: 'Объем',
            dataIndex: 'surplus',
            key: 'surplus',
            width: '20%',
            render: (text, record) => (
                <InputNumber
                    value={record.surplus}
                    min={0}
                    step={50000}
                    onChange={(value) => handleInputChange(record.advNumber, 'surplus', value)}
                    changeOnWheel
                />
            ),
        },
        {
            title: 'Обновить',
            key: 'update',
            render: (text, record) => (
                <Tooltip title="Редактировать">
                    <Button
                        icon={<SaveOutlined />}
                        onClick={() => handleEdit(record.advNumber, record.minAmount, record.maxAmount, record.surplus)}
                    />
                </Tooltip>
            ),
            width: '10%',
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => (
                <Switch
                    checkedChildren="Вкл"
                    unCheckedChildren="Выкл"
                    checked={record.status}
                    loading={loadingStatus[record.advNumber]}
                    onChange={(checked) => handleStatusChange(record.advNumber, checked)}
                />
            ),
            width: '10%',
        },
        {
            title: 'Удалить',
            key: 'delete',
            render: (text, record) => (
                <Tooltip title="Удалить">
                    <Button
                        icon={<DeleteOutlined />}
                        type="primary"
                        danger
                        onClick={() => openDeleteConfirm(record)}
                    />
                </Tooltip>
            ),
            width: '10%',
        },
    ];

    const handleOpenModal = () => {
        setModalVisible(true);
    };

    const handleCancelModal = () => {
        setModalVisible(false);
        form.resetFields();
    };

    return (
        <>
            <Table columns={columns} dataSource={groups} loading={loading} rowKey="advNumber" pagination={false}
                   footer={() => (
                       <div style={{ textAlign: 'left' }}>
                           <Button type="primary" onClick={handleOpenModal}>Новое объявление</Button>
                       </div>
                   )}
            />

            <Modal
                title="Новое объявление"
                open={modalVisible}
                onOk={handleCreateApplication}
                onCancel={handleCancelModal}
                okText="Сохранить"
                cancelText="Отмена"
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        payMethod: 'Сбербанк',
                        minAmount: 50000,
                        maxAmount: 400000,
                        surplus: 1000000,
                    }}
                >
                    <Form.Item
                        name="payMethod"
                        label="Метод оплаты"
                        rules={[{ required: true, message: 'Пожалуйста, выберите метод оплаты' }]}
                    >
                        <Select>
                            <Select.Option value="Сбербанк">Сбербанк</Select.Option>
                            <Select.Option value="Тинькофф">Тинькофф</Select.Option>
                            <Select.Option value="СБП">СБП</Select.Option>
                            <Select.Option value="Райффайзенбанк">Райффайзенбанк</Select.Option>
                        </Select>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="minAmount"
                                label="Минимальная сумма"
                                rules={[{ required: true, message: 'Пожалуйста, введите минимальную сумму' }]}
                            >
                                <InputNumber
                                    min={2000}
                                    max={400000}
                                    step={1000}
                                    style={{ width: '100%' }}
                                    changeOnWheel
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="maxAmount"
                                label="Максимальная сумма"
                                rules={[{ required: true, message: 'Пожалуйста, введите максимальную сумму' }]}
                            >
                                <InputNumber
                                    min={2000}
                                    max={400000}
                                    step={1000}
                                    style={{ width: '100%' }}
                                    changeOnWheel
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="surplus"
                        label="Объем"
                        rules={[{ required: true, message: 'Пожалуйста, введите объем' }]}
                    >
                        <InputNumber
                            min={0}
                            step={50000}
                            style={{ width: '100%' }}
                            changeOnWheel
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Подтверждение удаления"
                open={deleteConfirmVisible}
                onOk={handleDelete}
                onCancel={() => setDeleteConfirmVisible(false)}
                okText="Удалить"
                cancelText="Отмена"
            >
                {selectedGroup && (
                    <p>Вы точно хотите удалить объявление для банка: {selectedGroup.payMethod}?</p>
                )}
            </Modal>
        </>
    );
};

export default DesAdv;
