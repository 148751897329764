import React, { useState } from 'react';
import { Tabs, Button } from 'antd';
import BrokerInfo from './BrokerInfo';
import Applications from './Applications';
import BrokerAdv from "./BrokerAdv";
import BrokerSession from "./SessionBroker";
import DashboardBroker from "./DashboardBroker";
import BrokerHistory from "./HistoryBroker/BrokerHistory";
const { TabPane } = Tabs;

const BrockerGeneral = () => {
    const [activeTab, setActiveTab] = useState('info'); // Для отслеживания активной вкладки
    // Создаем объект состояния для ключей перерендера каждого таба
    const [reloadKeys, setReloadKeys] = useState({
        info: 0,
        dashboard: 0,
        applications: 0,
        adv: 0,
        history: 0,
        session: 0
    });

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleRefresh = () => {
        // Обновляем ключ только для активного таба
        setReloadKeys(prevKeys => ({
            ...prevKeys,
            [activeTab]: prevKeys[activeTab] + 1
        }));
    };

    return (
        <div>
            <Tabs activeKey={activeTab} onChange={handleTabChange} tabBarStyle={{ marginBottom: '16px' }} tabBarExtraContent={{
                right: (
                    <Button onClick={handleRefresh} style={{ float: 'right', marginBottom: '16px' }}>Обновить</Button>
                ),
            }}>
                <TabPane tab="Информация" key="info">
                    <BrokerInfo key={reloadKeys.info} team={localStorage.getItem('team')} />
                </TabPane>
                <TabPane tab="Дашборд" key="dashboard">
                    <DashboardBroker key={reloadKeys.dashboard} team={localStorage.getItem('team')} />
                </TabPane>
                <TabPane tab="Заявки" key="applications">
                    <Applications key={reloadKeys.applications} team={localStorage.getItem('team')} />
                </TabPane>
                <TabPane tab="Объявление" key="adv">
                    <BrokerAdv key={reloadKeys.adv} team={localStorage.getItem('team')} />
                </TabPane>
                <TabPane tab="История" key="history">
                    <BrokerHistory key={reloadKeys.adv} team={localStorage.getItem('team')} />
                </TabPane>
                <TabPane tab="Сессия" key="session">
                    <BrokerSession key={reloadKeys.session} team={localStorage.getItem('team')} />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default BrockerGeneral;
