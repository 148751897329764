import React, { useState } from 'react';
import { Form, InputNumber, Button, DatePicker, Table, Row, Col, message } from 'antd';
import moment from 'moment';
import { postMyDoplata } from "../../config/crm/apiBroker";
import {handleCopyReplace} from "../../components/LogicComponents";

const BonusCalculationPage = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [period, setPeriod] = useState(''); // Изначально период пустой

    // Начальная пустая таблица с интервалами и нулями
    const initialTableData = [
        { key: '1', interval: '03:00-10:00', team1: 0, team2: 0, team3: 0, team4: 0 },
        { key: '2', interval: '10:00-15:00', team1: 0, team2: 0, team3: 0, team4: 0 },
        { key: '3', interval: '15:00-22:00', team1: 0, team2: 0, team3: 0, team4: 0 },
        { key: '4', interval: '22:00-03:00', team1: 0, team2: 0, team3: 0, team4: 0 },
    ];

    const [tableData, setTableData] = useState(initialTableData);

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            setPeriod('');
            const requestData = {
                amountTeam: {
                    "1": Number(values.teamA.toFixed(2)), // Преобразуем в число с двумя знаками после запятой
                    "2": Number(values.teamB.toFixed(2)),
                    "3": Number(values.teamC.toFixed(2)),
                    "4": Number(values.teamD.toFixed(2)),
                },
                dataStr: values.date.format("DD.MM.YYYY"), // Форматируем дату
                doplata: Number(values.doplata.toFixed(2)), // Преобразуем в число с двумя знаками после запятой
            };

            const response = await postMyDoplata(requestData);
            if (response.code === 1000) {
                setData(response.data);
                setPeriod(response.data.period); // Устанавливаем период из данных

                // Обновляем таблицу с новыми данными
                const updatedTableData = initialTableData.map((item, index) => ({
                    ...item,
                    team1: response.data.teams[0].intervals[index].bonusShare,
                    team2: response.data.teams[1].intervals[index].bonusShare,
                    team3: response.data.teams[2].intervals[index].bonusShare,
                    team4: response.data.teams[3].intervals[index].bonusShare,
                }));
                setTableData(updatedTableData);
            } else {
                message.error('Ошибка при отправке данных');
                setTableData(initialTableData); // Если ошибка, сбрасываем все в 0
            }
        } catch (error) {
            message.error('Ошибка при отправке данных');
            setTableData(initialTableData); // Если ошибка, сбрасываем все в 0
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Интервал',
            dataIndex: 'interval',
            key: 'interval',
        },
        {
            title: 'Команда А',
            dataIndex: 'team1',
            key: 'team1',
            render: (item) => (
                <span onClick={() => handleCopyReplace(item)} style={{ cursor: 'pointer'}}>
                    {item}
                </span>
            ),
        },
        {
            title: 'Команда Б',
            dataIndex: 'team2',
            key: 'team2', render: (item) => (
                <span onClick={() => handleCopyReplace(item)} style={{ cursor: 'pointer'}}>
                    {item}
                </span>
            ),
        },
        {
            title: 'Команда В',
            dataIndex: 'team3',
            key: 'team3', render: (item) => (
                <span onClick={() => handleCopyReplace(item)} style={{ cursor: 'pointer'}}>
                    {item}
                </span>
            ),
        },
        {
            title: 'Команда М',
            dataIndex: 'team4',
            key: 'team4', render: (item) => (
                <span onClick={() => handleCopyReplace(item)} style={{ cursor: 'pointer'}}>
                    {item}
                </span>
            ),
        },
    ];

    return (
        <div>
            <h1>Расчет доплат</h1>
            <Form form={form} onFinish={handleSubmit} layout="horizontal">
                <Row gutter={16} justify="space-between" align="middle">
                    <Col span={4}>
                        <Form.Item
                            name="date"
                            label="Дата"
                            rules={[{ required: true, message: 'Пожалуйста, выберите дату' }]}
                        >
                            <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            name="teamA"
                            label="Команда A"
                            rules={[{ required: true, message: 'Введите сумму для Команды A' }]}
                        >
                            <InputNumber min={0} step={1} placeholder="0.00" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            name="teamB"
                            label="Команда Б"
                            rules={[{ required: true, message: 'Введите сумму для Команды Б' }]}
                        >
                            <InputNumber min={0} step={1} placeholder="0.00" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            name="teamC"
                            label="Команда В"
                            rules={[{ required: true, message: 'Введите сумму для Команды В' }]}
                        >
                            <InputNumber min={0} step={1} placeholder="0.00" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            name="teamD"
                            label="Команда М"
                            rules={[{ required: true, message: 'Введите сумму для Команды М' }]}
                        >
                            <InputNumber min={0} step={1} placeholder="0.00" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            name="doplata"
                            label="Доплата"
                            rules={[{ required: true, message: 'Введите сумму доплаты' }]}
                        >
                            <InputNumber min={0} step={1} placeholder="0.00" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
                            Отправить
                        </Button>
                    </Col>
                </Row>
            </Form>
            <h2>Период: {period || '—'}</h2> {/* Если данных нет, отображаем пустое значение */}
            <Table
                columns={columns}
                dataSource={tableData}
                rowKey="key"
                loading={loading}
                pagination={false}
            />
        </div>
    );
};

export default BonusCalculationPage;
