import React from 'react';
import DesPage from "./DesPage/DesPage";
import BrokerPage from "./BrokerPage/BrokerPage";

const ProssPage = ({size=12}) => {
    const team = localStorage.getItem('team');

    if (["1", "2", "3", "4"].includes(team)) {
        return <BrokerPage sise={size} />;
    } else if (team === "6") {
        return <DesPage sise={size}/>;
    } else {
        return <div>Нет доступа</div>;
    }
};

export default ProssPage;
