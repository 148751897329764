import React, { useEffect,useState, useCallback } from 'react';
import OrderTable from "./components/OrderTable";
import OrderFilters from "./components/OrderFilters";
import { postMyHistory} from "../../../../config/crm/apiBroker";


const BrokerHistory = () => {
    const ITEMS_PER_PAGE = 10;
    const team = localStorage.getItem('team');
    console.log(team)
    const [filters, setFilters] = useState({
        orderStatus: 'all',
        totalPriceStart: '',
        totalPriceEnd: '',
        createTimeStart: '',
        createTimeEnd: '',
        orderNumber: '',
        team: team,
        payCard: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE); // Начальное значение
    const [loadingTable, setLoadingTable] = useState(false); // Начальное значение

    const updateFilter = useCallback((key, value) => {
        setFilters(prev => ({ ...prev, [key]: value }));
    }, []);

    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalOrders, setTotalOrders] = useState(0);

    const fetchOrders =  useCallback(async(page) => {
        const payload = {
            ...filters,
            createTimeStart: filters.createTimeStart ? (new Date(filters.createTimeStart).getTime()) : null,
            createTimeEnd: filters.createTimeEnd ? (new Date(filters.createTimeEnd).getTime()) : null,
            orderStatus: filters.orderStatus !== 'all' ? filters.orderStatus : null,
            orderNumber: filters.orderNumber ? String(filters.orderNumber) : null,
            size: itemsPerPage,
            page,
            totalPriceStart: parseFloat(filters.totalPriceStart) || null,
            totalPriceEnd: parseFloat(filters.totalPriceEnd) || null,
            payCard: filters.payCard ? String(filters.payCard) : null,
        };
        setLoadingTable(true)
        const response = await postMyHistory(payload)
        const dataGo = response.data.map(item => ({ ...item, key: item._id }))
        setTableData(dataGo);
        setTotalOrders(response.total);
        setLoadingTable(false)
    }, [filters,itemsPerPage]);

    useEffect(() => {
        fetchOrders(1); // Вызываем handleSearch для первой страницы
    }, [fetchOrders]); // Передаем handleSearch как зависимость


    const [isAdvancedSearchVisible, setIsAdvancedSearchVisible] = useState(false);

    const newSearch = useCallback(async () => {
        setCurrentPage(1);
        await fetchOrders(1);
    }, [fetchOrders]);

    const handlePageClick = async (page) => {
        setCurrentPage(page);
        await fetchOrders(page);
    };
    return (
        <>
            <OrderFilters
                filters={filters}
                updateFilter={updateFilter}
                newSearch={newSearch}
                isAdvancedSearchVisible={isAdvancedSearchVisible}
                setIsAdvancedSearchVisible={setIsAdvancedSearchVisible}
            />
            <OrderTable
                tableData={tableData}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalOrders={totalOrders}
                handlePageClick={handlePageClick}
                setItemsPerPage = {setItemsPerPage}
                setCurrentPage={setCurrentPage}
                loadingTable = {loadingTable}
            />

        </>

    );
}

export default BrokerHistory;
