import React, { useState, useEffect } from 'react';
import { Skeleton, Card, Row, Col, Button } from 'antd';
import { postDesInfoCourse, postDesInfoUser, postDesInfoBalance,postDesStatUser } from "../../../config/crm/apiDes";
import { addToast } from "../../../components/Notification/Toast";
import {formatNumber} from "../../../components/numericFunc";
import { ReloadOutlined } from '@ant-design/icons';

const cardInfoList = [
    { key: 'buy', title: 'Курс', formatter: value => formatNumber(value) },
    { key: 'available', title: 'Баланс', formatter: value => formatNumber(value, true, "USDT") },
    { key: 'freeze', title: 'Баланс ❄️', formatter: value => formatNumber(value, true, "USDT") },
    { key: 'login', title: 'Аккаунт', formatter: value => value },
    { key: 'bidBenefit', title: 'Ставка', formatter: value => `${value} %` },
    { key: 'stat', title: 'Объем', formatter: value => formatNumber(value, true, ) },
];

const InfoCard = ({ title, value, loading, size, right }) => {
    return (
        <Card size={size} title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>{title}{right}</div>}>
            {loading ? (
                <Skeleton.Input active={true} size={'small'} />
            ) : (
                <span>{value}</span>
            )}
        </Card>
    );
};

const DesInfo = ({ team }) => {
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [loadingBalance, setLoadingBalance] = useState(false);
    const [loadingCourse, setLoadingCourse] = useState(false);
    const [loadingStat, setLoadingStat] = useState(false);
    const [data, setData] = useState({
        login: '',
        bidBenefit: null,
        available: null,
        freeze: null,
        buy: null,
        stat: null
    });

    const fetchData = async () => {
        try {
            setLoadingInfo(true);
            setLoadingBalance(true);
            setLoadingCourse(true);
            setLoadingStat(true);
            const [userResponse, balanceResponse, courseResponse,statResponse] = await Promise.all([
                postDesInfoUser(team),
                postDesInfoBalance(team),
                postDesInfoCourse(team),
                postDesStatUser(team)
            ]);

            if (userResponse.code === 1000 && balanceResponse.code === 1000 && courseResponse.code === 1000 && statResponse.code === 1000 ) {
                setData({
                    login: userResponse.data.login,
                    bidBenefit: userResponse.data.bidBenefit,
                    available: balanceResponse.data.available,
                    freeze: balanceResponse.data.freeze,
                    buy: courseResponse.data.buy,
                    stat: statResponse.data.fiat
                });
            } else {
                await addToast('warning', 'Ошибка', 'Получение информации');
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
        } finally {
            setLoadingInfo(false);
            setLoadingBalance(false);
            setLoadingCourse(false);
            setLoadingStat(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [team]);

    const handleRefreshBalance = async () => {
        try {
            setLoadingBalance(true);
            const balanceResponse = await postDesInfoBalance(team);
            if (balanceResponse.code === 1000) {
                setData(prevData => ({
                    ...prevData,
                    available: balanceResponse.data.available,
                    freeze: balanceResponse.data.freeze,
                }));
            } else {
                await addToast('warning', 'Ошибка', 'Обновление баланса');
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
        } finally {
            setLoadingBalance(false);
        }
    };

    const handleRefreshCourse = async () => {
        try {
            setLoadingCourse(true);
            const courseResponse = await postDesInfoCourse(team);
            if (courseResponse.code === 1000) {
                setData(prevData => ({
                    ...prevData,
                    buy: courseResponse.data.buy,
                }));
            } else {
                await addToast('warning', 'Ошибка', 'Обновление курса');
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
        } finally {
            setLoadingCourse(false);
        }
    };

    const handleRefreshStat = async () => {
        try {
            setLoadingStat(true);
            const courseResponse = await postDesStatUser(team);
            if (courseResponse.code === 1000) {
                setData(prevData => ({
                    ...prevData,
                    stat: courseResponse.data.fiat,
                }));
            } else {
                await addToast('warning', 'Ошибка', 'Обновление статистики');
            }
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);
        } finally {
            setLoadingStat(false);
        }
    };
    return (
        <Row gutter={[16, 16]}>
            {cardInfoList.map(cardInfo => (
                <Col span={8} key={cardInfo.key}>
                    <InfoCard
                        title={cardInfo.title}
                        value={cardInfo.formatter(data[cardInfo.key])}
                        loading={
                            (loadingInfo && cardInfo.key === 'login') ||
                            (loadingInfo && cardInfo.key === 'bidBenefit') ||
                            (loadingBalance && (cardInfo.key === 'available' || cardInfo.key === 'freeze')) ||
                            (loadingCourse && cardInfo.key === 'buy')||
                            (loadingStat && cardInfo.key === 'stat')
                        }
                        size="small"
                        right={
                            (cardInfo.key === 'available') ? (
                                <Button type="text" icon={<ReloadOutlined />} onClick={handleRefreshBalance} />
                            ) : cardInfo.key === 'buy' ? (
                                <Button type="text" icon={<ReloadOutlined />} onClick={handleRefreshCourse} />
                            ) : cardInfo.key === 'stat' ? (
                                    <Button type="text" icon={<ReloadOutlined />} onClick={handleRefreshStat} />
                                ) :
                                null
                        }
                    />
                </Col>
            ))}
        </Row>
    );
};

export default DesInfo;