import React, { useState, useEffect } from 'react';
import { Modal, List, Button, Input, Table, Badge, Tag, Skeleton } from 'antd';
import { formattedDate } from '../dateFunc';
import { formatNumber } from '../numericFunc';
import { crmAgentInfo, crmOrderInfo, crmOrdersInfo, crmOrderAdd, crmAgentAdd } from '../../config/crm/apiNote';
import ConsoleMain from "./ConsoleMain";
import {ChatButton} from "../CommonComponents";

const OrderHistoryModal = ({ visible, onClose, agentUserId }) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeOrder, setActiveOrder] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalOrders, setTotalOrders] = useState(0);

    useEffect(() => {
        if (visible) {
            fetchOrders();
        }
    }, [visible, agentUserId, currentPage, pageSize]); // Добавляем currentPage и pageSize в зависимости

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response = await crmOrdersInfo({
                agentUserId,
                page: currentPage,
                limit: pageSize
            });
            setOrders(response.data || []); // Assuming `orders` array is inside `data`
            setTotalOrders(response.total || 0); // Assuming total count is in `total`
        } catch (error) {
            console.error('Failed to fetch orders:', error);
        } finally {
            setLoading(false);
        }
    };
    const toggleModal = (item) => {
        setActiveOrder(item);
        setIsModalOpen(!isModalOpen);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const getStatusBadge = (status) => {
        switch (status) {
            case 'COMPLETE':
                return <Badge status="success" text="Завершено" />;
            case 'PEND':
                return <Badge status="warning" text="Ожидание оплаты" />;
            case 'PAID':
                return <Badge status="warning" text="Ожидание актива" />;
            case 'CANCEL':
                return <Badge status="error" text="Отменен" />;
            case 'CANCEL_SYS':
                return <Badge status="error" text="Отменен с." />;
            default:
                return <Badge status="default" text={status} />;
        }
    };

    const columns = [
        {
            title: 'Дата',
            width: '18%',
            fixed: 'left',
            dataIndex: 'tsCreate',
            key: 'tsCreate',
            render: tsCreate => formattedDate(tsCreate),
        },
        {
            title: 'Ордер',
            width: '20%',
            dataIndex: 'tradeType',
            key: 'orderNumber',
            render: (tradeType, record) => (
                <Tag color={tradeType === 'BUY' ? '#87d068' : '#f50'}>
                    {record.orderNumber}
                </Tag>
            ),
        },
        {
            title: 'Статус',
            width: '18%',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            render: getStatusBadge,
        },
        {
            title: 'Никнейм',
            width: '20%',
            dataIndex: 'userNickName',
            key: 'userNickName',
        },
        {
            title: 'Сумма',
            width: '15%',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            render: totalPrice => formatNumber(totalPrice, true),
        },
        {
            title: 'Чат',
            key: 'chat',
            render: (text, record) => (
                <ChatButton
                    msgCount={record.msgCount}
                    onClick={() => {
                        toggleModal(record);
                    }}
                />
            ),
        },
    ];

    const data = orders.map(order => ({
        ...order,
        key: order.orderNumber,
    }));


    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    return (
        <>
            <Modal open={visible} onCancel={onClose} footer={null}
                   title={loading ? <Skeleton.Input style={{ width: 200 }} active size="small" /> : `История ордеров ${totalOrders}`}

                   width={1000}>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: totalOrders,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                    }}
                    scroll={{ y: 400 }}
                    onChange={handleTableChange}
                    rowClassName={(record) => (record.tradeType === 'SELL' ? 'sell-row' : 'buy-row')}
                />
            </Modal>
            {activeOrder && (
                <Modal
                    open={isModalOpen}
                    onCancel={closeModal}
                    footer={null}
                    width={800}
                    style={{ alignItems: 'center', justifyContent: 'center', top: 8 }}
                >
                    {activeOrder && (
                        <ConsoleMain
                            tradeType={activeOrder.tradeType}
                            userNumber={activeOrder.userNumber}
                            orderNumber={activeOrder.orderNumber}
                            key={refreshKey}
                            setKey={setRefreshKey}
                            sizeChat={250}
                            done={false}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};


const NotesModal = ({ visible, onClose, orderNumber }) => {
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState('');

    useEffect(() => {
        setNotes([])
        if (visible) {
            const fetchNotes = async () => {
                const response = await crmOrderInfo({ orderNumber });
                setNotes(response.data);
            };
            fetchNotes();
        }
    }, [visible, orderNumber]);

    const handleAddNote = async () => {
        const userData = JSON.parse(localStorage.getItem('dataUser'));
        const lastName = userData ? userData.lastName : 'Фамилия';
        const firstName = userData ? userData.firstName : 'Имя';
        const merch = `${lastName} ${firstName}`;

        await crmOrderAdd({ orderNumber, note, merch });
        // Re-fetch the notes
        const orderNoteResponse = await crmOrderInfo({ orderNumber });
        setNotes(orderNoteResponse.data);
        setNote('');
    };

    return (
        <Modal visible={visible} onCancel={onClose} footer={null} title="Пометки к ордеру">
            <List
                itemLayout="horizontal"
                dataSource={notes}
                renderItem={note => (
                    <List.Item>
                        <List.Item.Meta
                            title={`${formattedDate(note.tsCreate)} ${note.merch}`}
                            description={note.note}
                        />
                    </List.Item>
                )}
                style={{ maxHeight: '400px', overflowY: 'scroll' }}
            />
            <Input.TextArea
                rows={4}
                value={note}
                onChange={e => setNote(e.target.value)}
                placeholder="Оставить пометку"
            />
            <Button type="primary" onClick={handleAddNote} style={{ marginTop: '10px' }}>
                Добавить пометку
            </Button>
        </Modal>
    );
};

const AgentInfoModal = ({ visible, onClose, agentUserId }) => {
    const [agentInfo, setAgentInfo] = useState([]);
    const [note, setNote] = useState('');

    useEffect(() => {
        if (visible) {
            const fetchAgentInfo = async () => {
                const response = await crmAgentInfo({ agentUserId });
                setAgentInfo(response.data);
            };
            fetchAgentInfo();
        }
    }, [visible, agentUserId]);

    const handleAddNote = async () => {
        const userData = JSON.parse(localStorage.getItem('dataUser'));
        const lastName = userData ? userData.lastName : 'Фамилия';
        const firstName = userData ? userData.firstName : 'Имя';
        const merch = `${lastName} ${firstName}`;

        await crmAgentAdd({ agentUserId, note, merch });
        // Re-fetch the agent notes
        const agentResponse = await crmAgentInfo({ agentUserId });
        setAgentInfo(agentResponse.data);
        setNote('');
    };

    return (
        <Modal visible={visible} onCancel={onClose} footer={null} title="Информация о контрагенте">
            <List
                itemLayout="horizontal"
                dataSource={agentInfo}
                renderItem={info => (
                    <List.Item>
                        <List.Item.Meta
                            title={`${formattedDate(info.tsCreate)} ${info.merch}`}
                            description={info.note}
                        />
                    </List.Item>
                )}
                style={{ maxHeight: '400px', overflowY: 'scroll' }}
            />
            <Input.TextArea
                rows={4}
                value={note}
                onChange={e => setNote(e.target.value)}
                placeholder="Оставить пометку"
            />
            <Button type="primary" onClick={handleAddNote} style={{ marginTop: '10px' }}>
                Добавить пометку
            </Button>
        </Modal>
    );
};

export { OrderHistoryModal, NotesModal, AgentInfoModal };