import React, { useEffect, useState } from 'react';
import {
    Radio,
    Input,
    Select,
    Row,
    Col,
    Switch,
    Skeleton,
    Tooltip
} from 'antd';
import { observer } from "mobx-react";
import { Flipper, Flipped } from 'react-flip-toolkit';
import { TwoColLayout } from "../../../Layouts/formRow/2col";
import GlassPriceStore from "../../../state/GlassPriceStore";
import { CustomCard, CustomCardRow } from "../../../Layouts/Card/CustomCard";
import { assetsOptions, payOptionsArray } from "../../../config/constants";
import PriceStore from "../../../state/PriceStore";
import {BankList} from "../../../components/CommonComponents";
const getChangeIcon = (change) => {
    switch (change) {
        case 'up':
            return '🔼';
        case 'down':
            return '🔽';
        case 'new':
            return '🆕';
        case 'surplusChanged':
            return '💰';
        default:
            return '';
    }
};

const MySelect = ({ value, onChange, options }) => (
    <Select
        defaultValue={value}
        size="small"
        style={{ width: "100%" }}
        onChange={onChange}
    >
        {options.map((option) => (
            <Select.Option
                key={option.value}
                value={option.value}
                style={{ backgroundColor: option.color || 'transparent' }}
            >
                {option.label}
            </Select.Option>
        ))}
    </Select>
);

const CardGlass = ({ dataGlass }) => {
    if (!dataGlass || dataGlass.length === 0) {
        return <div>Нет данных для отображения</div>;
    }
    return (
        <Flipper flipKey={dataGlass.map(item => item.adv).join("-")}>
            {dataGlass.map((item, index) => (
                <Flipped key={item.adv} flipId={item.adv}>
                    <div
                        key={item.adv}
                        style={{
                            fontSize: '12px',
                            margin: '0',
                            backgroundColor: item.isMerchant && item.backgroundColor === 'defaultColor' ? '#e3e3e3' : item.backgroundColor,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: index % 10 === 9 ? '2px solid #ccc' : 'none' // граница после каждого 10-го элемента
                        }}
                    >
                        <div style={{flex: 1}}>
                            {item.change && item.change !== 'none' ? (
                                <span>{getChangeIcon(item.change)}</span>
                            ) : (
                                <span>{index + 1}.</span>
                            )}
                            <span style={{fontSize: '14px'}}>
                                {item.online ? '🟢' : '🔴'} <b>{item.price} ₽</b>
                            </span>
                            {item.triangle && (
                                <span>🔖</span>
                            )}
                            <span style={{fontSize: '14px'}}>
                                <Tooltip title={`${item.orderCount} (${item.rate} %)`} placement={"topRight"}>
                                  <span style={{cursor: 'pointer'}}>{" "}{item.nickName}</span>
                                </Tooltip>
                            </span>
                            <span style={{fontSize: '14px'}}>
                                {" "} {item.minAmount} - {item.maxAmount}
                            </span>
                            <span style={{fontSize: '14px'}}>
                                <b> ({item.surplus})</b>
                            </span>
                        </div>
                        <div style={{marginLeft: 'auto'}}> {/* Этот div прижмет банки к правому краю */}
                            <BankList bankNames={item.payMethods} />
                        </div>
                    </div>
                </Flipped>
            ))}
        </Flipper>

    );
};

const GlassPrice = observer(() => {
    const [position, setPosition] = useState(GlassPriceStore.bank);
    const [formattedBuyData, setFormattedBuyData] = useState([]);
    const [formattedSellData, setFormattedSellData] = useState([]);
    useEffect(() => {
        const fetchData = () => {
            GlassPriceStore.fetchData(false); // Регулярный запрос без изменения состояния загрузки
            setFormattedBuyData(GlassPriceStore.formattedBuyData);
            setFormattedSellData(GlassPriceStore.formattedSellData);
        };
        fetchData();

        const intervalId = setInterval(() => {
            fetchData(); // Вызов каждые 3 секунды
        }, 3000);

        // Очистка при размонтировании
        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'KeyQ') {  // KeyQ всегда соответствует клавише 'Q'
                setPosition('Tinkoff');
                GlassPriceStore.handleSelectBank('Tinkoff')
            } else if (event.code === 'KeyW') {  // KeyQ всегда соответствует клавише 'Q'
                setPosition('Sberbank');
                GlassPriceStore.handleSelectBank('Sberbank')
            } else if (event.code === 'KeyE') {  // KeyQ всегда соответствует клавише 'Q'
                setPosition('Raiffeisenbank');
                GlassPriceStore.handleSelectBank('Raiffeisenbank')
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    useEffect(() => {
        setFormattedBuyData([]);
        setFormattedSellData([]);
        GlassPriceStore.setPrev(); // Очистка предыдущих данных в Store

        const fetchData = async () => {
            await GlassPriceStore.fetchData(true); // Выполнение нового запроса с изменением состояния загрузки
            setFormattedBuyData(GlassPriceStore.formattedBuyData);
            setFormattedSellData(GlassPriceStore.formattedSellData);
        };

        fetchData(); // Вызов функции загрузки данных
    }, [PriceStore.marketActive, GlassPriceStore.asset, GlassPriceStore.bank]);

    const handlePositionChange = (e) => {
        setPosition(e.target.value);
        GlassPriceStore.handleSelectBank(e.target.value);
    };
    return (
        <>
            <CustomCardRow
                children={
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                            <Switch
                                style={{width: "100%"}}
                                checkedChildren="Мерчанты"
                                unCheckedChildren="Все"
                                checked={GlassPriceStore.merchChecked}
                                onChange={() => GlassPriceStore.toggleMerchChecked()}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                            <Switch
                                style={{ width: "100%" }}
                                checkedChildren="Онлайн"
                                unCheckedChildren="Все"
                                checked={GlassPriceStore.isOnline}
                                onChange={() => GlassPriceStore.toggleIsOnline()}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                            <Switch
                                style={{ width: "100%" }}
                                checkedChildren="Без 🔖"
                                unCheckedChildren="Все"
                                checked={GlassPriceStore.isTriangle}
                                onChange={(value) => GlassPriceStore.toggleIsTriangle()}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                            <MySelect
                                value={GlassPriceStore.asset}
                                onChange={(value) => GlassPriceStore.handleSelectAsset(value)}
                                options={assetsOptions}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                            <Input
                                style={{ width: "100%" }}
                                size="small"
                                placeholder="От"
                                type="number"
                                step="1000"
                                value={GlassPriceStore.minAmountFilter || "0"}
                                onChange={(e) => GlassPriceStore.setMinAmountFilter(e.target.value.replace(/\D/g, ""))}
                                pattern="[0-9]*"
                            />
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={9}>
                            <Radio.Group size={'small'} value={position} onChange={handlePositionChange}             style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
                            >
                                {payOptionsArray.map(option => (
                                    <Radio.Button key={option.value} value={option.value}
                                                  style={{
                                                      backgroundColor: position === option.value ? option.color : 'transparent',
                                                      color: position === option.value ? '#fff' : '#000',
                                                      border: '1px solid',
                                                      borderColor: position === option.value ? option.color : '#d9d9d9',
                                                      padding: '0 10px',
                                                      transition: 'all 0.3s',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                  }}

                                    >
                                        {option.label}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </Col>
                    </Row>
                }
                children2={
                    <TwoColLayout
                        col1={() => (
                            <CustomCard vhPx={345}>
                                {GlassPriceStore.loading ? (
                                    <Skeleton active />
                                ) : (
                                    <CardGlass dataGlass={formattedBuyData} label="Продажа" />
                                )}
                            </CustomCard>
                        )}
                        col2={() => (
                            <CustomCard vhPx={345}>
                                {GlassPriceStore.loading ? (
                                    <Skeleton active />
                                ) : (
                                    <CardGlass dataGlass={formattedSellData} label="Покупка" />
                                )}
                            </CustomCard>
                        )}
                    />
                }
            />
        </>
    );
});

export default GlassPrice;
