import React, {useEffect, useState, useCallback} from "react";
import {  Progress, Switch, Button, Spin } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import MerchStore from "../../../../state/MerchStore";
import { fetchMerchGoogleInfo } from "../../../../config/crm/apiMerch";
import {fetchWorkStatus,fetchWorkGo} from "../../../../config/api/apiGeneral";
import { CustomTableVh } from "../../../../Layouts/Card/CustomCard";
import { handleCopy } from "../../../../components/LogicComponents";
import { addToastUser } from "../../../../components/Notification/Toast";

export const TOTPTimer = () => {
    const interval = 30;

    // Функция для получения оставшихся секунд в текущем интервале
    const getSecondsLeft = () => {
        const now = new Date();
        return interval - (now.getSeconds() % interval);
    }

    const [secondsLeft, setSecondsLeft] = useState(getSecondsLeft());

    const updateTimer = useCallback(() => {
        setSecondsLeft(getSecondsLeft);
    }, []);

    useEffect(() => {
        const timerId = setInterval(updateTimer, 1000);
        return () => clearInterval(timerId);
    }, [updateTimer]);

    const percent = Math.round((secondsLeft / interval) * 100);

    return (
        <div className="progress-container">
            <Progress
                percent={percent}
                status="active"
                showInfo={false}
                type="circle"
                size={40}
                strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                }}
            />
            <div className="timer-text">{secondsLeft}</div>
        </div>
    );
};


const AddGoogle = () => {
    const [userStates, setUserStates] = useState({}); // Состояние для хранения статусов пользователей
    const [loadingStates, setLoadingStates] = useState({});  // Для отслеживания загрузки

    const handleCopyClick = async (nickName, marketName, userNumber) => {
        try {
            const response = await fetchMerchGoogleInfo(userNumber);
            await handleCopy(response.data,`${marketName} ${nickName}`)
        } catch (error) {
            await addToastUser(userNumber,'error','Произошла ошибка запроса')
        }
    };
    const handleSwitchChange = async (checked, userNumber) => {
        const typeWork = checked ? 'on' : 'off';
        setLoadingStates(prev => ({ ...prev, [userNumber]: true }));
        try {
            await fetchWorkGo({ userNumber, typeWork });
            setUserStates(prev => ({ ...prev, [userNumber]: checked }));
            addToastUser(userNumber, 'success', `Работа ${checked ? 'включена' : 'выключена'}`);
        } catch (error) {
            console.error(error);
            addToastUser(userNumber, 'error', 'Произошла ошибка при изменении статуса');
        } finally {
            setLoadingStates(prev => ({ ...prev, [userNumber]: false }));
        }
    };
    const loadInitialStates = async () => {
        setLoadingStates(MerchStore.userNumbers.reduce((acc, user) => ({ ...acc, [user.userNumber]: true }), {}));
        try {
            const users = MerchStore.userNumbers;
            const statuses = await Promise.all(users.map(async user => {
                try {
                    const statusResponse = await fetchWorkStatus({ userNumber: user.userNumber });
                    // Если статус недоступен или равен null, установим false
                    const isStatusTrue = statusResponse?.data?.status === true;
                    return { userNumber: user.userNumber, state: isStatusTrue };
                } catch (error) {
                    // При возникновении ошибки устанавливаем state в false
                    console.error(`Error fetching status for user ${user.userNumber}:`, error);
                    return { userNumber: user.userNumber, state: false };
                }
            }));
            setUserStates(statuses.reduce((acc, curr) => ({ ...acc, [curr.userNumber]: curr.state }), {}));
        } finally {
            setLoadingStates(MerchStore.userNumbers.reduce((acc, user) => ({ ...acc, [user.userNumber]: false }), {}));
        }
    };

    useEffect(() => {
        loadInitialStates();
    }, []); // или другая переменная, которая изменяется при открытии компонента

    const columns = [
        {
            title: 'Биржа',
            dataIndex: 'marketName',
            key: 'marketName',
            filters: MerchStore.markets.map(market => ({
                text: market.marketName,
                value: market.marketName,
            })),
            onFilter: (value, record) => record.marketName === value,
        },
        {
            title: 'Никнейм',
            dataIndex: 'nickName',
            key: 'nickName',
        },
        {
            title: 'Действие',
            key: 'action',
            render: (text, record) => (
                <Button
                    icon={<CopyOutlined />}
                    onClick={() => handleCopyClick(record.nickName, record.marketName, record.userNumber)}
                />
            ),
        },
        {
            title: 'Статус',
            key: 'status',
            render: (_, record) => (
                    <Switch
                        size="small"
                        checked={userStates[record.userNumber]}
                        onChange={(checked) => handleSwitchChange(checked, record.userNumber)}
                        disabled={loadingStates[record.userNumber]}
                        loading={loadingStates[record.userNumber] === true}
                    />
            ),
        },
    ];

    return (
        <CustomTableVh
            vhPx = {150}
            dataSource={MerchStore.userNumbers}
            columns={columns}
            rowKey="userNumber"
            pagination={false}
        />
    );
}
export default AddGoogle;