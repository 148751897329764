import React, { useState, useEffect } from "react";
import {Button, Card, Drawer, Form, Select, InputNumber, Switch, Space, message} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {fetchBotNew, fetchCheckCourse} from "../../../config/api/apiBot";
import MerchStore from "../../../state/MerchStore";

const { Option } = Select;
const { Item } = Form;

// Функции валидации
const validateLimit = (_, value) => {
    if (value !== 0 && (value < 500 || value > 3000000)) {
        return Promise.reject(new Error('Введите 0 или число в пределах от 500 до 3 000 000'));
    }
    return Promise.resolve();
};

const validateAutoCourseLimit = (_, value) => {
    if (value !== 0 && (value < 500 || value > 3000000)) {
        return Promise.reject(new Error('Введите 0 или число в пределах от 500 до 3 000 000'));
    }
    return Promise.resolve();
};

const validateMinMaxPrice = (_, value) => {
    if (value < 0.5 || value > 8000000) {
        return Promise.reject(new Error('Введите число в пределах от 0.5 до 8 000 000'));
    }
    return Promise.resolve();
};

const validateUnderPrice = (_, value) => {
    if (value < 0.01 || value > 1000) {
        return Promise.reject(new Error('Введите число в пределах от 0.01  до 1000'));
    }
    return Promise.resolve();
};

const validateVariancePrice = (form) => (_, value) => {
    const underPrice = form.getFieldValue('underPrice');
    if (value < underPrice) {
        return Promise.reject(new Error('Значение должно быть больше или равно "Перебивать на"'));
    }
    if (value < 0.01 || value > 1000) {
        return Promise.reject(new Error('Введите число в пределах от 0.01 до 1000'));
    }
    return Promise.resolve();
};

const validatePercentage = (_, value) => {
    if (value < -35 || value > 35) {
        return Promise.reject(new Error('Введите число в диапазоне от -35 до 35'));
    }
    return Promise.resolve();
};

const validateSpeed = (_, value) => {
    if (value < 2 || value > 300) {
        return Promise.reject(new Error('Введите значение от 2 до 300'));
    }
    return Promise.resolve();
};

const SelectInput = ({ name, label, value, options, onChange }) => (
    <Item name={name}  label={label}>
        <Select value={value} onChange={onChange}>
            {options.map((option) => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
            ))}
        </Select>
    </Item>
);

const candleTypes = {
    "openPrice": "Открытие", "highPrice": "Максимум", "lowPrice": "Минимум",
    "closePrice": "Закрытие", "averagePrice": "Средняя цена"
};
const candleTypeOptions = Object.entries(candleTypes).map(([value, label]) => ({ value, label }));

const validIntervals = {
    "1m": "1 минута", "3m": "3 минуты", "5m": "5 минут", "15m": "15 минут",
    "30m": "30 минут", "1h": "1 час", "2h": "2 часа", "4h": "4 часа",
    "6h": "6 часов", "8h": "8 часов", "12h": "12 часов", "1d": "1 день",
    "3d": "3 дня", "1w": "1 неделя", "1M": "1 месяц"
};

const intervalOptions = Object.entries(validIntervals).map(([value, label]) => ({ value, label }));

const tradeTypeOptions = [
    { value: "SELL", label: "Продажа" },
    { value: "BUY", label: "Покупка" }
];

const positionOptions = Array.from({ length: 10 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));

const NumberInput = ({ name, label, value, onChange, validator, min, max, step, disabled }) => (
    <Item name={name} label={label} rules={[{ validator }]}>
        <InputNumber min={min} max={max} step={step} value={value} onChange={onChange} style={{ width: "100%" }} disabled={disabled} />
    </Item>
);

const SwitchInput = ({ name, label, checked, onChange }) => (
    <Item name={name} label={label}>
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={checked} onChange={onChange} />
    </Item>
);

const AutoCourseSettings = ({ formСourse, botSettings, autoSettings, spotSettings, p2pSettings, setAutoSettings, setSpotSettings, setP2pSettings, pay, adsAsset, autoConfig, setAutoConfig }) => {

    const [loadingAutoCourse, setLoadingAutoCourse] = useState(false);
    const [priceAutoCourse, setPriceAutoCourse] = useState(0)
    useEffect(() => {
        if (botSettings.autoCourse) {
            let config = autoSettings;
            if (autoSettings.typeAuto === "SPOT") {
                config = { ...config, "SPOT": spotSettings };
            } else if (autoSettings.typeAuto === "P2P") {
                config = { ...config, "P2P": p2pSettings };
            } else if (autoSettings.typeAuto === "MIXED") {
                config = { ...config, "SPOT": spotSettings, "P2P": p2pSettings };
            }
            setAutoConfig(config);
        } else {
            setAutoConfig(null);
        }
    }, [spotSettings, p2pSettings,autoSettings, botSettings.autoCourse, autoSettings.typeAuto]);


    const btnCheckCourse = async () => {
        setLoadingAutoCourse(true); // Активация индикатора загрузки перед запросом
        console.log(autoConfig)
        try {
            const response = await fetchCheckCourse(autoConfig);
            if (response.code == 1000) {
                const data = response.data;
                setPriceAutoCourse(data.price); // Установка полученной цены
            } else {
                setPriceAutoCourse(0); // Установка цены в 0, если код ответа не 1000
            }
        } catch (error) {
            console.error(error); // Логирование ошибки в консоль
            setPriceAutoCourse(0); // Установка цены в 0 при возникновении ошибки
        } finally {
            setLoadingAutoCourse(false); // Выключение индикатора загрузки после запроса
        }
    };
    const autoCourseTypeOptions = [
        { value: "P2P", label: "P2P" },
        { value: "SPOT", label: "SPOT" }
    ];
    if (adsAsset !== "USDT") {
        autoCourseTypeOptions.push({ value: "MIXED", label: "Смешанный" });
    }
    const SubmitButtonCourse = ({ form }) => {
        const [submittable, setSubmittable] = React.useState(false);

        // Watch all values
        const values = Form.useWatch([], form);
        React.useEffect(() => {
            form
                .validateFields({
                    validateOnly: true,
                })
                .then(
                    () => {
                        setSubmittable(true);
                    },
                    () => {
                        setSubmittable(false);
                    },
                );
        }, [values]);
        return (
            <Button type="primary"  disabled={!submittable} loading={loadingAutoCourse} onClick={btnCheckCourse}>
                Чекнуть курс
            </Button>
        );
    };
    const onInputChange = (nameConst, field, value) => {
        const stateSetters = {
            autoSettings: setAutoSettings,
            spotSettings: setSpotSettings,
            p2pSettings: setP2pSettings
        };
        const setState = stateSetters[nameConst];
        if (setState) {
            setState(prev => ({ ...prev, [field]: value }));
        }
    };
    return (
        <Card title= "Автокурс"
              size={'small'}
              style={{marginBottom: 16}}
              extra={
                  priceAutoCourse !== 0 && (
                      <span>
                          <b>{Number(priceAutoCourse).toLocaleString('ru-RU')}</b> рублей
                      </span>
                  )
              }
        >
            <Form
                form={formСourse}
                layout="horizontal"
                labelCol={{span:8}}
                wrapperCol={{span:16}}
            >
                <SelectInput
                    name="typeAuto"
                    label="Тип автокурса"
                    value={autoSettings.typeAuto}
                    options={autoCourseTypeOptions}
                    onChange={(value) => onInputChange('autoSettings', 'typeAuto', value)}
                />
                {(autoSettings.typeAuto === "SPOT" || autoSettings.typeAuto === "MIXED" ) && (
                    <>
                        <SelectInput
                            name="interval"
                            label="Интервал"
                            value={spotSettings.interval}
                            options={intervalOptions}
                            onChange={(value) => onInputChange('spotSettings', 'interval', value)}
                        />
                        <SelectInput
                            name="typeCandle"
                            label="Тип свечи"
                            value={spotSettings.typeCandle}
                            options={candleTypeOptions}
                            onChange={(value) => onInputChange('spotSettings', 'typeCandle', value)}
                        />
                    </>
                )}
                {(autoSettings.typeAuto === "P2P" || autoSettings.typeAuto === "MIXED") && (
                    <>
                        <SelectInput
                            name="averageHuman"
                            label="Среднняя из"
                            value={p2pSettings.averageHuman}
                            options={positionOptions}
                            onChange={(value) => onInputChange('p2pSettings','averageHuman', value)}
                        />
                        <SwitchInput
                            name="isMerchant"
                            label="Мерчанты"
                            checked={p2pSettings.isMerchant}
                            onChange={(checked) => onInputChange('p2pSettings','isMerchant', checked)}
                        />
                        <SwitchInput
                            name="isTriangle"
                            label="Убрать треугольные"
                            checked={p2pSettings.isTriangle}
                            onChange={(checked) => onInputChange('p2pSettings','isTriangle', checked)}
                        />
                        <SelectInput
                            name="pay"
                            label="Способ Оплаты"
                            value={p2pSettings.pay}
                            options={pay.map(p => ({ value: p, label: p }))}
                                onChange={(value) => onInputChange('p2pSettings','pay', value)}
                        />
                        <SelectInput
                            name="tradeType"
                            label="Тип стакана"
                            value={p2pSettings.tradeType}
                            options={tradeTypeOptions}
                            onChange={(value) => onInputChange('p2pSettings','tradeType', value)}
                        />
                        <NumberInput
                            name='amount'
                            label="от Суммы"
                            value={p2pSettings.amount}
                            onChange={(value) => onInputChange('p2pSettings', 'amount', value)}
                            validator={validateAutoCourseLimit}
                            min={0}
                            max={3000000}
                            step={500}
                        />
                    </>
                )}

                <NumberInput
                    name='difference'
                    label="Разница в %"
                    value={autoSettings.difference}
                    onChange={(value) => onInputChange('autoSettings', 'difference', value)}
                    validator={validatePercentage}
                    min={-35}
                    max={35}
                    step={0.01}
                />
                <NumberInput
                    name="speed"
                    label="Скорость обнов"
                    value={autoSettings.speed}
                    onChange={(value) => onInputChange('autoSettings','speed', value)}
                    validator={validateSpeed}
                    min={2}
                    max={300}
                    step={1}
                />
                <Form.Item>
                    <Space>
                        <SubmitButtonCourse form={formСourse} />
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    );
};

export const BotSettingsDrawOldConfig = ({configServer,title,onClose,botSettingsVisible,setBotSettingsVisible,pay}) => {
    return (
        <BotSettingsDrawer2
            title={title}
            onClose={onClose}
            botSettingsVisible={botSettingsVisible}
            setBotSettingsVisible={setBotSettingsVisible}
            pay={pay}
            configServer={configServer}
        />
    )
}

export const BotSettingsDrawer2 = ({title,onClose,botSettingsVisible,setBotSettingsVisible,pay, configServer }) => {
    const [form] = Form.useForm();
    const [formСourse] = Form.useForm();

    const [autoConfig, setAutoConfig] = useState(
        configServer.autoConfig == null
            ? null
            : configServer.autoConfig
    );
    const [p2pSettings, setP2pSettings] = useState(
        !configServer.autoConfig || !configServer.autoConfig.P2P
            ? {
                marketNumber: configServer.marketNumber,
                pay: pay[0],
                tradeType: configServer.tradeType,
                isMerchant: false,
                isTriangle: false,
                amount: 0,
                averageHuman: 3
            }
            : autoConfig.P2P
    );

    const [spotSettings, setSpotSettings] = useState(
        !configServer.autoConfig || !configServer.autoConfig.SPOT
            ? {
                interval: "5m",
                typeCandle: "averagePrice"
            }
            : autoConfig.SPOT
    );


    const [autoSettings, setAutoSettings] = useState(
        autoConfig == null
            ? {
                difference: 1,
                asset: configServer.asset,
                typeAuto: "SPOT",
                speed: 15
            }
            : {
                difference: autoConfig.difference,
                asset: configServer.asset,
                typeAuto: autoConfig.typeAuto,
                speed: autoConfig.speed
            }
    );
    const [botSettings, setBotSettings] = useState({
        asset: configServer.asset,
        botLimit: configServer.botLimit,
        position: configServer.position,
        filterAmount: configServer.filterAmount,
        priceMinMax: configServer.priceMinMax,
        underPrice: configServer.underPrice,
        variancePrice:configServer.variancePrice,
        isMerchant: configServer.isMerchant,
        pay: configServer.pay,
        speed: configServer.speed,
        roundPrice: configServer.roundPrice,
        autoCourse: configServer.autoCourse,
        autoConfig: configServer.autoConfig,

    });




    useEffect(() => {
        form.setFieldsValue({
            asset: botSettings.asset,
            botLimit: botSettings.botLimit,
            position: botSettings.position,
            filterAmount: botSettings.filterAmount,
            priceMinMax: botSettings.priceMinMax,
            underPrice: botSettings.underPrice,
            variancePrice:botSettings.variancePrice,
            isMerchant: botSettings.isMerchant,
            pay: botSettings.pay,
            speed: botSettings.speed,
            roundPrice: botSettings.roundPrice,
            autoCourse: botSettings.autoCourse,
            autoConfig: botSettings.autoConfig,
        });
    }, [form, botSettings, autoSettings]);

    useEffect(() => {
        formСourse.setFieldsValue({
            typeAuto: autoSettings.typeAuto,
            interval: spotSettings.interval,
            typeCandle: spotSettings.typeCandle,
            averageHuman: p2pSettings.averageHuman,
            isTriangle: p2pSettings.isTriangle,

            isMerchant: p2pSettings.isMerchant,
            pay: p2pSettings.pay,
            tradeType: p2pSettings.tradeType,
            amount: p2pSettings.amount,
            difference: autoSettings.difference,
            speed: autoSettings.speed
        });
    }, [autoSettings, spotSettings, p2pSettings, formСourse]);

    useEffect(() => {
        setBotSettings(prevSettings => ({ ...prevSettings,
            asset: configServer.asset,
            botLimit: configServer.botLimit,
            position: configServer.position,
            filterAmount: configServer.filterAmount,
            priceMinMax: configServer.priceMinMax,
            underPrice: configServer.underPrice,
            variancePrice:configServer.underPrice,
            isMerchant: configServer.isMerchant,
            pay: configServer.pay,
            speed: configServer.speed,
            roundPrice: configServer.roundPrice,
            autoCourse: configServer.autoCourse,
            autoConfig: configServer.autoConfig,
        }));
        setAutoSettings(prevSettings => ({ ...prevSettings, asset: configServer.asset}));
        setP2pSettings(prevSettings => ({ ...prevSettings, pay: pay[0] }));


    }, [title, configServer.asset, onClose, botSettingsVisible, pay, setBotSettingsVisible]); // Укажите здесь все зависимые пропсы


    const handleInputChange = (field, value) => {
        setBotSettings(prevSettings => ({ ...prevSettings, [field]: value }));
    };
    const btnNewBot = async (body) => {
        try {
            const team = localStorage.getItem('team');
            body = {
                ...body,
                team: team ? JSON.parse(team) : null, // Parse if the team is a JSON string, otherwise assign null
            };
            const re = await fetchBotNew(body); // Ожидаем завершения запроса

            if (re.status === true) { // Проверяем статус HTTP-ответа
                // Предполагаем, что успешный ответ возвращает статус 200
                message.success("Бот успешно добавлен");
            } else {
                // Обработка других статусов HTTP-ответа
                message.error("Произошла ошибка при добавлении бота");
            }
        } catch (error) {
            console.error("Ошибка при добавлении бота:", error);
            message.error("Ошибка при добавлении бота");
        }
    }
    const saveBotSettings = async () => {
        try {
            // Проверяем валидацию основной формы
            await form.validateFields();
            // Проверяем валидацию формы автокурса, если она активна
            if (botSettings.autoCourse) {
                await formСourse.validateFields();
            }

            let botData = {
                ...botSettings,
                userNumber: configServer.userNumber,
                marketNumber: configServer.marketNumber,
                nickName: configServer.nickName,
                adsNumber: configServer.adsNumber,
                tradeType: configServer.tradeType,
                // другие свойства
            };

            if (autoConfig != null) {
                botData = { ...botData, autoConfig: autoConfig };
            }

            // if (botSettings.autoCourse) {
            //     botData.priceMinMax = null;
            // }

            console.log(botData);
            btnNewBot(botData)
        } catch (error) {
            message.error('Заполните все поля');
            // Обработка ошибок валидации
        }
    };

    return (
        <Drawer title={`Настройка бота ${title}`} placement="right" width={500} onClose={onClose} open={botSettingsVisible}>
            <Form
                form={form}
                layout="horizontal"
            >
                <Card
                    title="Основные настройки"
                    size='small'
                    style={{marginBottom: 16}}
                >
                    <SelectInput
                        name="position"
                        label="Позиция"
                        value={botSettings.position}
                        options={positionOptions}
                        onChange={(value) => handleInputChange('position', value)}
                    />
                    <NumberInput
                        name="botLimit" label="Лимит от"
                        value={botSettings.botLimit}
                        onChange={(value) => handleInputChange('botLimit', value)}
                        validator={validateLimit}
                        min={0}
                        max={3000000}
                        step={500}
                    />
                    <SelectInput
                        name="pay" label="Оплата"
                        value={botSettings.pay} options={pay.map(p => ({ value: p, label: p }))}
                        onChange={(value) => handleInputChange('pay', value)}
                    />
                    <SwitchInput
                        label="Только мерчанты"
                        checked={botSettings.isMerchant}
                        onChange={(checked) => handleInputChange('isMerchant', checked)}
                    />
                    <NumberInput
                        name="priceMinMax"
                        label={configServer.tradeType === "SELL" ? "Мин цена" : "Мах цена"}
                        value={botSettings.priceMinMax}
                        onChange={(value) => handleInputChange('priceMinMax', value)} validator={validateMinMaxPrice}
                        min={0.5}
                        max={8000000}
                        step={0.01}
                        disabled={botSettings.autoCourse} />
                    <SwitchInput
                        name="autoCourse"
                        label="Автокурс" checked={botSettings.autoCourse}
                        onChange={(checked) => handleInputChange('autoCourse', checked)}
                    />
                </Card>
                {botSettings.autoCourse && (
                    <AutoCourseSettings
                        formСourse={formСourse}
                        botSettings={botSettings}
                        autoSettings={autoSettings}
                        spotSettings={spotSettings}
                        p2pSettings={p2pSettings}
                        setAutoSettings={setAutoSettings}
                        setSpotSettings={setSpotSettings}
                        setP2pSettings={setP2pSettings}
                        pay={pay}
                        adsAsset={configServer.asset}
                        autoConfig={autoConfig}
                        setAutoConfig ={setAutoConfig}
                    />)}

                <Card title="Дополнительные настройки" size='small'>
                    <NumberInput
                        name="underPrice"
                        label="Перебивать на"
                        value={botSettings.underPrice}
                        onChange={(value) => handleInputChange('underPrice', value)}
                        validator={validateUnderPrice}
                        min={0.01}
                        max={1000}
                        step={0.01}
                    />
                    <NumberInput
                        name="variancePrice"
                        label="Не агриться до"
                        value={botSettings.variancePrice}
                        onChange={(value) => handleInputChange('variancePrice', value)}
                        validator={validateVariancePrice(form)}
                        min={0.01}
                        max={1000}
                        step={0.01}
                    />
                    <NumberInput
                        name="filterAmount"
                        label="Фильтр (отсеять)"
                        value={botSettings.filterAmount}
                        onChange={(value) => handleInputChange('filterAmount', value)}
                        validator={validateLimit}
                        min={0}
                        max={100000}
                        step={500}
                    />

                    <NumberInput
                        name="speed"
                        label="Скорость обновления"
                        value={botSettings.speed}
                        onChange={(value) => handleInputChange('speed', value)}
                        validator={validateSpeed}
                        min={2}
                        max={300}
                        step={1}
                    />
                </Card>
                <Space>
                    <Button type="primary" onClick={saveBotSettings}>Сохранить</Button>
                </Space>
            </Form>
        </Drawer>
    );
};
export const BotSettingsDrawer = ({ title, adsNumber,adsAsset, onClose, botSettingsVisible, pay,userNumber, price, minAmount, tradeType, setBotSettingsVisible }) => {
    const [userInfo, setUserInfo] = useState(MerchStore.getUserInfoByUserNumber(userNumber));
    const [marketNumber, setMarketNumber] = useState(userInfo.marketNumber);
    const [nickName, setNickName] = useState(userInfo.nickName);
    const [form] = Form.useForm();
    const [formСourse] = Form.useForm();
    const [autoConfig, setAutoConfig] = useState(null)
    const [botSettings, setBotSettings] = useState({
        asset: adsAsset,
        botLimit: minAmount,
        position: 1,
        filterAmount: 5000,
        priceMinMax: price,
        underPrice: 0.01,
        variancePrice: 0.01,
        isMerchant: true,
        pay: pay[0],
        speed: 10,
        roundPrice: false,
        autoCourse: false,
        autoConfig: null,

    });
    const [autoSettings, setAutoSettings] = useState(
        {
            difference: 1,
            asset: adsAsset,
            typeAuto: "SPOT",
            speed: 15
        }
    );
    const [p2pSettings, setP2pSettings] = useState(
        {
            marketNumber: marketNumber,
            pay: pay[0],
            tradeType: tradeType,
            isMerchant: false,
            amount: 0,
            averageHuman: 3,
            isTriangle: false
        }
    );

    const [spotSettings, setSpotSettings] = useState(
        {
            interval: "5m",
            typeCandle: "averagePrice"
        }
    );
    useEffect(() => {
        // Обновляем userInfo при изменении userNumber
        const updatedUserInfo = MerchStore.getUserInfoByUserNumber(userNumber);
        setUserInfo(updatedUserInfo);

        // Обновляем marketNumber и nickName
        setMarketNumber(updatedUserInfo.marketNumber);
        setNickName(updatedUserInfo.nickName);
    }, [userNumber]);
    useEffect(() => {
        form.setFieldsValue({
            asset: botSettings.asset,
            botLimit: botSettings.botLimit,
            position: botSettings.position,
            filterAmount: botSettings.filterAmount,
            priceMinMax: botSettings.priceMinMax,
            underPrice: botSettings.underPrice,
            variancePrice:botSettings.variancePrice,
            isMerchant: botSettings.isMerchant,
            pay: botSettings.pay,
            speed: botSettings.speed,
            roundPrice: botSettings.roundPrice,
            autoCourse: botSettings.autoCourse,
            autoConfig: botSettings.autoConfig,
        });
    }, [form, botSettings, autoSettings]);

    useEffect(() => {
        formСourse.setFieldsValue({
            typeAuto: autoSettings.typeAuto,
            interval: spotSettings.interval,
            typeCandle: spotSettings.typeCandle,
            averageHuman: p2pSettings.averageHuman,
            isMerchant: p2pSettings.isMerchant,
            pay: p2pSettings.pay,
            tradeType: p2pSettings.tradeType,
            amount: p2pSettings.amount,
            isTriangle: p2pSettings.isTriangle,
            difference: autoSettings.difference,
            speed: autoSettings.speed
        });
    }, [autoSettings, spotSettings, p2pSettings, formСourse]);

    useEffect(() => {
        setBotSettings(prevSettings => ({ ...prevSettings,
            asset: adsAsset,
            botLimit: minAmount,
            position: 1,
            filterAmount: 5000,
            priceMinMax: price,
            underPrice: 0.01,
            variancePrice: 0.01,
            isMerchant: true,
            pay: pay[0],
            speed: 15,
            roundPrice: false,
            autoCourse: false,
            autoConfig: null,

        }));
        setAutoSettings(prevSettings => ({ ...prevSettings, asset: adsAsset}));
        setP2pSettings(prevSettings => ({ ...prevSettings, tradeType: tradeType, pay: pay[0] }));


    }, [title, adsAsset, onClose, botSettingsVisible, pay,userNumber, price, minAmount, tradeType, setBotSettingsVisible]); // Укажите здесь все зависимые пропсы


    const handleInputChange = (field, value) => {
        setBotSettings(prevSettings => ({ ...prevSettings, [field]: value }));
    };
    const btnNewBot = async (body) => {
        try {
            const team = localStorage.getItem('team');
            body = {
                ...body,
                team: team ? JSON.parse(team) : null, // Parse if the team is a JSON string, otherwise assign null
            };
            const re = await fetchBotNew(body); // Ожидаем завершения запроса

            if (re.status === true) { // Проверяем статус HTTP-ответа
                // Предполагаем, что успешный ответ возвращает статус 200
                message.success("Бот успешно добавлен");
            } else {
                // Обработка других статусов HTTP-ответа
                message.error("Произошла ошибка при добавлении бота");
            }
        } catch (error) {
            console.error("Ошибка при добавлении бота:", error);
            message.error("Ошибка при добавлении бота");
        }
    }
    const saveBotSettings = async () => {
        try {
            // Проверяем валидацию основной формы
            await form.validateFields();
            // Проверяем валидацию формы автокурса, если она активна
            if (botSettings.autoCourse) {
                await formСourse.validateFields();
            }

            let botData = {
                ...botSettings,
                userNumber: userNumber,
                marketNumber: marketNumber,
                nickName : nickName,
                adsNumber: adsNumber,
                tradeType: tradeType,
                autoConfig: autoConfig
            };

            // if (botSettings.autoCourse) {
            //     botData.priceMinMax = null;
            // }

            console.log(botData);
            btnNewBot(botData)
        } catch (error) {
            message.error('Заполните все поля');
            // Обработка ошибок валидации
        }
    };

    return (
        <Drawer title={`Настройка бота ${title}`} placement="right" width={500} onClose={onClose} open={botSettingsVisible}>
            <Form
                form={form}
                layout="horizontal"
            >
                <Card
                    title="Основные настройки"
                    size='small'
                    style={{marginBottom: 16}}
                >
                    <SelectInput
                        name="position"
                        label="Позиция"
                        value={botSettings.position}
                        options={positionOptions}
                        onChange={(value) => handleInputChange('position', value)}
                    />
                    <NumberInput
                        name="botLimit" label="Лимит от"
                        value={botSettings.botLimit}
                        onChange={(value) => handleInputChange('botLimit', value)}
                        validator={validateLimit}
                        min={0}
                        max={3000000}
                        step={500}
                    />
                    <SelectInput
                        name="pay" label="Оплата"
                        value={botSettings.pay}
                        options={pay.map(p => ({ value: p, label: p }))
                    }
                        onChange={(value) => handleInputChange('pay', value)}
                    />
                    <SwitchInput
                        label="Только мерчанты"
                        checked={botSettings.isMerchant}
                        onChange={(checked) => handleInputChange('isMerchant', checked)}
                    />
                    <NumberInput
                        name="priceMinMax"
                        label={tradeType === "SELL" ? "Мин цена" : "Мах цена"}
                        value={botSettings.priceMinMax}
                        onChange={(value) => handleInputChange('priceMinMax', value)} validator={validateMinMaxPrice}
                        min={0.5}
                        max={8000000}
                        step={0.01}
                        disabled={botSettings.autoCourse} />
                    <SwitchInput
                        name="autoCourse"
                        label="Автокурс" checked={botSettings.autoCourse}
                        onChange={(checked) => handleInputChange('autoCourse', checked)}
                    />
                </Card>
                {botSettings.autoCourse && (
                <AutoCourseSettings
                    formСourse={formСourse}
                    botSettings={botSettings}
                    autoSettings={autoSettings}
                    spotSettings={spotSettings}
                    p2pSettings={p2pSettings}
                    setAutoSettings={setAutoSettings}
                    setSpotSettings={setSpotSettings}
                    setP2pSettings={setP2pSettings}
                    pay={pay}
                    adsAsset={adsAsset}
                    autoConfig={autoConfig}
                    setAutoConfig ={setAutoConfig}
                />)}

                <Card title="Дополнительные настройки" size='small'>
                    <NumberInput
                        name="underPrice"
                        label="Перебивать на"
                        value={botSettings.underPrice}
                        onChange={(value) => handleInputChange('underPrice', value)}
                        validator={validateUnderPrice}
                        min={0.01}
                        max={1000}
                        step={0.01}
                    />
                    <NumberInput
                        name="variancePrice"
                        label="Не агриться до"
                        value={botSettings.variancePrice}
                        onChange={(value) => handleInputChange('variancePrice', value)}
                        validator={validateVariancePrice(form)}
                        min={0.01}
                        max={1000}
                        step={0.01}
                    />
                    <NumberInput
                        name="filterAmount"
                        label="Фильтр (отсеять)"
                        value={botSettings.filterAmount}
                        onChange={(value) => handleInputChange('filterAmount', value)}
                        validator={validateLimit}
                        min={0}
                        max={100000}
                        step={500}
                    />

                    <NumberInput
                        name="speed"
                        label="Скорость обновления"
                        value={botSettings.speed}
                        onChange={(value) => handleInputChange('speed', value)}
                        validator={validateSpeed}
                        min={2}
                        max={300}
                        step={1}
                    />
                </Card>
                <Space>
                    <Button type="primary" onClick={saveBotSettings}>Сохранить</Button>
                </Space>
            </Form>
        </Drawer>
    );
};

