import { BOT_URL } from "../configUrls";
import { postApiRequest, getApiRequest } from "../apiParams";

export const fetchBotNew = (body) => {
    return postApiRequest(`${BOT_URL}/new-bot`, body );

};;


export const fetchBotList = (team) => {
    return postApiRequest(`${BOT_URL}/list-bots` ,{team});

};

export const fetchBotToggle = (body) => {
    return postApiRequest(`${BOT_URL}/toggle-bot`, body);

};

export const fetchCheckCourse = (body) => {
    return postApiRequest(`${BOT_URL}/check-course`, body);

};

export const fetchBotUpdate = (body) => {
    return postApiRequest(`${BOT_URL}/update-bot`, body);

};


export const fetchBotConfig = (body) => {
    return postApiRequest(`${BOT_URL}/get-config`, body);

};

export const fetchBotDelete = (body) => {
    return postApiRequest(`${BOT_URL}/delete-config`, body);

};

