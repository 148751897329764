import React, { useCallback, useEffect, useState,useRef  } from 'react';
import { Button, Upload, Image , Spin, Table, Tabs, message, Modal, Card, Pagination, Row, Col } from 'antd';
import { postBrokerOrders, postBrokerAccept, postBrokerDone } from "../../../config/crm/apiBroker";
import { UploadOutlined, ReloadOutlined,CopyOutlined } from '@ant-design/icons';
import { handleCopyPay, handleDisabled, ReverseTimer, handleCopy } from "../../../components/LogicComponents";
import OrderModal from "./ModalComponent";
import './customModal.css';
import {formatNumber} from "../../../components/numericFunc";
import {BankImageWrapper} from "../../../components/CommonComponents";
import {addToast} from "../../../components/Notification/Toast";
const { TabPane } = Tabs;
const refreshInterval = 30; // 60 секунд
const { Dragger } = Upload;

const UploadModal = ({ visible, onClose, onSubmit, item }) => {
    const [fileList, setFileList] = useState([]);
    const [dragOver, setDragOver] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        // Добавляем обработчик вставки через Ctrl+V на всю страницу
        const handlePaste = (event) => {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
            for (let item of items) {
                if (item.kind === 'file' && item.type.startsWith('image/')) {
                    const file = item.getAsFile();
                    if (fileList.length === 0) {
                        const newFileList = [{
                            uid: '-1',
                            name: file.name,
                            status: 'done',
                            url: URL.createObjectURL(file),
                            originFileObj: file,
                        }];
                        setFileList(newFileList);
                    } else {
                        message.error('Можно загрузить только один файл.');
                    }
                    break;
                }
            }
        };

        document.body.addEventListener('paste', handlePaste);

        return () => {
            document.body.removeEventListener('paste', handlePaste);
        };
    }, [fileList]);

    const handleFileChange = (info) => {
        let fileList = [...info.fileList];

        // Limit to one file
        fileList = fileList.slice(-1);

        // Add url for preview
        fileList = fileList.map(file => ({
            ...file,
            url: URL.createObjectURL(file.originFileObj),
        }));

        setFileList(fileList);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const files = [...e.dataTransfer.files];
        if (files.length > 0 && fileList.length === 0) {
            const file = files[0];
            const newFileList = [{
                uid: '-1',
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
                originFileObj: file,
            }];
            setFileList(newFileList);
        }
    };


    const handleOpenDialog = () => {
        fileInputRef.current.click(); // открываем диалог выбора файла
    };

    const handleSend = () => {
        if (fileList.length === 0) {
            message.error('Пожалуйста, загрузите файл.');
            return;
        }
        onSubmit(fileList, item);
        setFileList([]);
        onClose();
    };

    const handlePreviewClick = (e) => {
        if (fileList.length === 0) {
            handleOpenDialog(); // открываем диалог выбора файла при клике на предпросмотр, если файл не загружен
        }
        e.preventDefault(); // отменяем стандартное действие клика, чтобы не открывался обзор файлов
    };

    const beforeUpload = (file) => {
        // Предотвращаем автоматическую загрузку файла
        return false;
    };

    return (
        <Modal
            title={item ? `Документ на сумму ${formatNumber(item.totalPrice)} карты ${item.payCard}` : 'Загрузить изображение'}
            open={visible}
            onOk={handleSend} // вызываем handleSend при нажатии кнопки "Отправить"
            onCancel={onClose}
            centered
            okText="Отправить"
            cancelText="Отменить"
        >
            <div
                className={`upload-area ${dragOver ? 'drag-over' : ''}`}
                onClick={handlePreviewClick}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {fileList.length === 0 ? (
                    <>
                        <UploadOutlined style={{ fontSize: 40, marginBottom: 8 }} />
                        <p>Перетащите файл сюда или вставьте с помощью Ctrl+V</p>
                    </>
                ) : (
                    <Image
                        width={200}
                        src={fileList[0].url}
                        alt={fileList[0].name}
                        style={{ display: 'block', margin: '0 auto' }}
                    />
                )}
            </div>
            <Upload
                accept=".jpg,.jpeg"
                fileList={fileList}
                beforeUpload={beforeUpload} // предотвращаем автоматическую загрузку при выборе файла
                onChange={handleFileChange}
                style={{ display: 'none' }} // скрываем стандартный компонент загрузки файла
            >
                <Button ref={fileInputRef} style={{ display: 'none' }} />
            </Upload>
        </Modal>
    );
};


const OrdersBroker = ({ dataOrders,setDataOrders, setSelectedItem, setModalVisible, setIsUploadModalVisible, setCurrentItem }) => {
    const [activeButtonsPayCard, setActiveButtonsPayCard] = useState(
        dataOrders.map(() => ({ disabled: true, success: false }))
    );
    const [timeLeft, setTimeLeft] = useState({});

    const [activeButtonsTotalPrice, setActiveButtonsTotalPrice] = useState(
        dataOrders.map(() => ({ disabled: true, success: false }))
    );
    const [scrollY, setScrollY] = useState(window.innerHeight);

    const createStatusButton = (status, item, text, className) => (
        <button className={`btn btn-${className} btn-sm w-100`}>{text}</button>
    );

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeLeft = {};
            dataOrders.forEach(order => {
                const endTime = order.tsNotifyPaidEnd || order.tsNotifyPendEnd;
                if (endTime) {
                    const remainingTime = Math.floor((new Date(endTime) - new Date()) / 1000);
                    if (remainingTime > 0) {
                        newTimeLeft[order.orderNumber] = remainingTime;
                    }
                }
            });
            setTimeLeft(newTimeLeft);
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, [dataOrders]);
    const updateTableScroll = () => {
        const otherElementsHeight = 200; // Примерное значение, измените на ваше
        const availableHeight = window.innerHeight - otherElementsHeight;
        setScrollY(availableHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', updateTableScroll);

        // Вызов при монтировании
        updateTableScroll();

        // Очистка при размонтировании
        return () => {
            window.removeEventListener('resize', updateTableScroll);
        };
    }, []);
    const handleContextMenu2 = (e, item) => {
        e.preventDefault();
        setCurrentItem(item);
        setIsUploadModalVisible(true);
    };

    const copyPayCardBotAndDeactivate = async (text, team, orderNumber, tradeType) => {
        await handleCopyPay(text, "Карта", () => {
            setActiveButtonsPayCard((prevState) => ({
                ...prevState,
                [orderNumber]: { disabled: true, success: true }
            }));
        });
    };

    const handleRightClick = async (event, orderNumber, type) => {
        event.preventDefault(); // Предотвращаем появление стандартного контекстного меню

        // Проверяем, заблокирована ли кнопка
        const isButtonDisabled = type === 'payCard'
            ? activeButtonsPayCard[orderNumber]?.disabled
            : activeButtonsTotalPrice[orderNumber]?.disabled;

        if (isButtonDisabled) {
            // Если кнопка заблокирована, то разблокируем ее
            if (type === 'payCard') {
                setActiveButtonsPayCard(prevState => ({
                    ...prevState,
                    [orderNumber]: { disabled: false, success: false }
                }));
                await handleDisabled('"Номер карта"')
            } else if (type === 'totalPrice') {
                setActiveButtonsTotalPrice(prevState => ({
                    ...prevState,
                    [orderNumber]: { disabled: false, success: false }
                }));
                await handleDisabled('"Итоговая цена"')
            }
        }
        // Если кнопка не заблокирована, ничего не делаем
    };

    const handleAcceptOrder = async (team, orderNumber) => {
        const body = { team, orderNumber };

        try {
            const response = await postBrokerAccept(body);

            if (response.code === 1000) {
                const updatedOrder = response.data;
                setDataOrders((prevOrders) =>
                    prevOrders.map((order) =>
                        order.orderNumber === orderNumber ? { ...order, ...updatedOrder } : order
                    )
                );
                message.success(`Ордер ${orderNumber} успещно подвержден`);
            } else {
                message.error(`Ошибка подтверждения ордера`);
            }
        } catch (error) {
            await addToast('warning', 'Ошибка', `${error.message}`);
            message.error(`Ошибка подтверждения ордера`);
        }
    };

    const StatusColumnRender = (status, item) => {

        let buttonClass = 'btn btn-outline-secondary btn-sm w-100';
        if ((status === 'PEND' && timeLeft[item.orderNumber] <= 180) || (status === 'PAID' && timeLeft[item.orderNumber] <= 300)) {
            buttonClass = 'btn btn-outline-danger btn-sm w-100 btn-blink';
        }
        switch (status) {
            case 'COMPLETE':
                return (
                    <button className={`btn btn-success btn-sm w-100`}>Исполнен</button>
                );
            case 'CANCEL':
                return createStatusButton(status, item, 'Отменен', 'warning');
            case 'APPEAL':
                return createStatusButton(status, item, 'Апелляция', 'danger');
            case 'PEND':
                return (
                    <button
                        className={buttonClass}
                        onClick={() => {
                            handleAcceptOrder(item.team, item.orderNumber)
                        }}
                    >
                        Принять {' '}
                        <ReverseTimer date={item.tsNotifyPendEnd} />
                    </button>
                );

            case 'PAID':
                const confirmText = item.paidStatus === false ? 'Подтвердить ≈' : 'Подтвердить';
                const reverseTimerDate = item.paidStatus === false ? item.tsCreate + item.timePend * 60 * 1000 : item.tsNotifyPaidEnd;
                return (
                    <button
                        className={buttonClass}
                        onClick={(e) => handleContextMenu2(e, item)}
                    >
                        {confirmText} {' '}
                        <ReverseTimer date={reverseTimerDate}/>
                    </button>
                );
            default:
                return null;
        }
    };

    const PayCardColumnRender = (_, item) => {
        const isDisabled = item.orderStatus === 'APPEAL' || item.orderStatus === 'COMPLETE' || activeButtonsPayCard[item.orderNumber]?.disabled;
        const shouldButtonBeGreenAndDisabled = isDisabled && item.tradeType === "BUY";
        const successClass = shouldButtonBeGreenAndDisabled || activeButtonsPayCard[item.orderNumber]?.success ? 'btn-success' : 'btn-outline-secondary';

        return (
            <button
                className={`btn ${successClass} ${isDisabled ? 'disabled-style' : ''} btn-sm w-100`}
                onClick={() => {
                    if (!isDisabled) {
                        copyPayCardBotAndDeactivate(item.payCard, item.team, item.orderNumber, item.tradeType);
                    }
                }}
                onContextMenu={(e) => handleRightClick(e, item.orderNumber, 'payCard')}
            >
                {item.payCard || "Карта"}
            </button>
        );
    };

    const SpeedButton = (_, item) => {

        return (
            <button
                className={`btn btn-sm btn-outline-secondary w-100`}
                onClick={() => {
                 handleCopy(`Одним платежом пожалуйста\nРовно ${item.totalPrice} ₽\n${item.bankName}\n${item.payCard}`,)
                    setActiveButtonsPayCard((prevState) => ({
                        ...prevState,
                        [item.orderNumber]: { disabled: true, success: true }
                    }));
                }}
            >
                <CopyOutlined />
            </button>
        );
    };
    const copyTotalPriceAndDeactivate = async (text, orderNumber) => {
        await handleCopyPay(text, "Цена", () => {
            setActiveButtonsTotalPrice((prevState) => ({
                ...prevState,
                [orderNumber]: { disabled: true, success: true }
            }));
        });
    };

    const TotalPriceColumnRender = (_, item) => {
        const isDisabled = item.orderStatus === 'APPEAL' || item.orderStatus === 'COMPLETE' || activeButtonsTotalPrice[item.orderNumber]?.disabled;
        const shouldButtonBeGreenAndDisabled = isDisabled && item.tradeType === "BUY";
        const successClass = shouldButtonBeGreenAndDisabled || activeButtonsTotalPrice[item.orderNumber]?.success ? 'btn-success' : 'btn-outline-secondary';

        return (
            <button
                className={`btn ${successClass} ${isDisabled ? 'disabled-style' : ''} btn-sm w-100`}
                onClick={() => {
                    if (!isDisabled) {
                        copyTotalPriceAndDeactivate(Number(item.totalPrice), item.orderNumber);
                    }
                }}
                onContextMenu={(e) => handleRightClick(e, item.orderNumber, 'totalPrice')}
            >
                {Number(item.totalPrice).toLocaleString('ru-RU')}
            </button>
        );
    };

    const handleOpenModal = (item) => {
        setSelectedItem(item);
        setModalVisible(true);
    };

    const OpenOrderRender = (_, item) => (

        <div
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenModal(item)}
        >
            <BankImageWrapper bankName={item.bankName}/>
        </div>
    );

    const getColumns = () => [
        {
            title: 'Карта',
            dataIndex: 'payCard',
            key: 'payCard',
            width: '20%',
            render: PayCardColumnRender,
        },
        {
            title: 'Сумма',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: '18%',
            sorter: (a, b) => a.totalPrice - b.totalPrice,
            render: TotalPriceColumnRender,
        },
        {
            title: 'Форма',
            dataIndex: 'payCard',
            key: 'speed',
            width: '9%',
            render: SpeedButton,
        },
        {
            title: 'Статус',
            dataIndex: 'orderStatus',
            width: '25%',
            key: 'orderStatus',
            render: StatusColumnRender,
        },
        {
            title: 'Ордер',
            dataIndex: 'orderNumber',
            width: '10%',
            key: 'orderNumber',
            render: (text, item) => {
                const handleClick = async () => {
                    await handleCopy(` ${item.orderNumber}\n`, "Ордер", () => {
                    });
                };

                return (
                    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {item.orderNumber}
                    </span>
                );
            }
        },
        {
            title: 'Банк',
            width: '7%',
            dataIndex: 'bankName',
            key: 'bankName',
            render: OpenOrderRender,
        },
    ];

    return (
        <Table
            columns={getColumns()}
            dataSource={dataOrders.map((order, index) => ({
                ...order,
                key: `${order.orderNumber}_${index}`, // Использование индекса для уникальности
            }))}
            locale={{ emptyText: 'Нет ордеров' }}
            pagination={{
                pageSize: 50,
            }}
            scroll={{ y: scrollY ? `${scrollY - 120}px` : undefined }}

        />
    );
};

const OrdersDuplicates = ({ dataDuplicates }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 6; // Максимум 4 карточки на странице

    // Получаем данные для текущей страницы
    const paginatedData = dataDuplicates.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    // Обработчик изменения страницы
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Асинхронный обработчик клика
    const handlePriceClick = async (totalPrice) => {
        await handleCopy(totalPrice, "Сумма", () => {});
    };

    return (
        <div>
            <Row gutter={[4, 4]} justify="start" align="middle">
                {paginatedData.map((order, index) => (
                    <Col key={`${order.totalPrice}_${index}`} span={4}>
                        <Card
                            size="small"
                            bodyStyle={{ padding: '8px' }}
                            style={{ textAlign: 'center', fontSize: '12px' }}
                        >
                            <div
                                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                onClick={() => handlePriceClick(order.totalPrice)}
                            >
                                {Number(order.totalPrice).toLocaleString('ru-RU')}
                            </div>
                            <div style={{ color: '#888' }}>
                                Кол-во: {order.count}
                            </div>
                            <div>
                                <BankImageWrapper bankName={order.bankName} />
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={dataDuplicates.length}
                onChange={handlePageChange}
                style={{ marginTop: '8px', textAlign: 'center' }}
            />
        </div>
    );
};

const BrokerOrders = (manager=false) => {
    const [dataOrders, setDataOrders] = useState([]);
    const [dataDuplicates, setDataDuplicates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('Активные');
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [countdown, setCountdown] = useState(refreshInterval);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [fileList, setFileList] = useState([]);
    const fetchOrders = useCallback(async () => {
        setLoading(true);
        const team = localStorage.getItem('team');
        const body = { team, status: ["PEND", "PAID", "APPEAL", "COMPLETE","CANCEL"] };

        try {
            const response = await postBrokerOrders(body);
            setDataOrders(response.data);
            setDataDuplicates(response.duplicates)
        } catch (error) {
            await addToast('warning', 'Внимание', `${error.message}`);

            message.error(`Не удалось обновить список ордеров`);

        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        let intervalId;
        let countdownInterval;

        if (autoRefresh) {
            intervalId = setInterval(() => {
                fetchOrders();
                setCountdown(refreshInterval); // Сбросить таймер
            }, refreshInterval * 1000); // Запустить автообновление каждые 60 секунд

            countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown > 0 ? prevCountdown - 1 : refreshInterval);
            }, 1000); // Уменьшить счетчик каждую секунду
        } else {
            setCountdown(refreshInterval); // Сбросить таймер при выключении автообновления
        }

        return () => {
            clearInterval(intervalId);
            clearInterval(countdownInterval);
        };
    }, [autoRefresh]);

    const handleRightClick2 = useCallback((event) => {
        event.preventDefault();
        setAutoRefresh(!autoRefresh);
    }, [autoRefresh]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedItem(null);
    };

    const handleUploadModalClose = () => {
        setIsUploadModalVisible(false);
        setCurrentItem(null);
        setFileList([]); // Clear file list after submission

    };

    const handleModalSubmit = async (fileList, item) => {
        const file = fileList[0];
        const reader = new FileReader();

        reader.onloadend = async () => {
            const base64data = reader.result.split(',')[1];
            const body = {
                orderNumber: item.orderNumber,
                team: item.team,
                totalPrice: item.totalPrice,
                proofs: base64data,
                filename: file.name,  // Добавляем имя файла в тело запроса

            };
            try {
                const response = await postBrokerDone(body);
                if (response.code === 1000) {
                    const updatedOrder = response.data;
                    setDataOrders((prevOrders) =>
                        prevOrders.map((order) =>
                            order.orderNumber === item.orderNumber ? { ...order, ...updatedOrder } : order
                        )
                    );

                    message.success(`Файл на сумму ${item.totalPrice} успешно загружен`);
                } else {
                    message.error(`Unexpected response code: ${response.code}`);
                }

            } catch (error) {
                message.error('Файл не загружен');
            }
        };

        reader.readAsDataURL(file.originFileObj);
    };

    const updateButton = (
        <Button
            onContextMenu={handleRightClick2}
            onClick={() => {
                setLoading(true);
                fetchOrders();
            }}
            disabled={loading}
            icon={loading ? <Spin /> : <ReloadOutlined />}
        >
            Обновить {autoRefresh ? `(${countdown} сек)` : ""}
        </Button>
    );

    let tabBarExtraContent = {
        right: updateButton
    };

    const ordersByStatus = {
        PEND: [],
        PAID: [],
        APPEAL: [],
        CANCEL: [],
        COMPLETE: [],
    };

    dataOrders.forEach(order => {
        ordersByStatus[order.orderStatus].push(order);
    });
    const activeOrders = [...ordersByStatus.PEND, ...ordersByStatus.PAID];
    activeOrders.sort((a, b) => a.orderNumber - b.orderNumber);


    const tabData = [
        { id: 'Активные', data: activeOrders },
        { id: 'Апелляции', data: ordersByStatus.APPEAL },
        { id: 'Отмененные', data: ordersByStatus.CANCEL },
        { id: 'Завершенные', data: ordersByStatus.COMPLETE },
    ];
    const renderTabTitle = (tab, amount) => `${tab.id} (${amount})`;

    return (
        <>
            <Tabs
                defaultActiveKey="Активные"
                activeKey={activeTab}
                onChange={setActiveTab}
                tabBarExtraContent={tabBarExtraContent}
            >
                {tabData.map(tab => {
                    const amount = tab.data.length; // Используем количество ордеров для подсчета
                    return (
                        <TabPane tab={renderTabTitle(tab, amount)} key={tab.id}>
                            <OrdersBroker
                                dataOrders={tab.data}
                                setDataOrders={setDataOrders}
                                setSelectedItem={setSelectedItem}
                                setModalVisible={setModalVisible}
                                setIsUploadModalVisible={setIsUploadModalVisible}
                                setCurrentItem={setCurrentItem}
                            />
                            <OrdersDuplicates
                                dataDuplicates={dataDuplicates}
                            />
                        </TabPane>
                    );
                })}
            </Tabs>
            <OrderModal
                visible={modalVisible}
                onClose={handleCloseModal}
                team={selectedItem?.team}
                orderNumber={selectedItem?.orderNumber}
                fetchOrders={fetchOrders}
            />
            <UploadModal
                visible={isUploadModalVisible}
                onClose={handleUploadModalClose}
                onSubmit={handleModalSubmit}
                item={currentItem}
                fileList={fileList}
                setFileList={setFileList}
            />
        </>
    );
};

export default BrokerOrders;
